import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import BarChartD3 from './pages/BarChartD3';
import VirtualMergeD3 from './pages/VirtualMergeD3';
import NotFound from './pages/Page404';
import LearnMore from './pages/LearnMore';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/stats',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/stats/Performance" replace /> },
        { path: 'Performance', element: <BarChartD3 /> },
        { path: 'VirtualMerge', element: <VirtualMergeD3 /> },
        { path: 'LearnMore', element: <LearnMore /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        // { path: 'login', element: <Login /> },
        // { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/stats/Performance" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
