/* eslint-disable */
import React, { useRef } from 'react';
import * as d3 from 'd3';
import { useD3 } from '../../../hooks/useD3';


const mobileWidth = 480;
const minHeight = 280;

function wrap(text, width) {
  text.each(function () {
      var text = d3.select(this),
          words = text.text().split(/\s+/),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.0, // ems
          x = text.attr("x"),
          y = text.attr("y"),
          dy = 0, //parseFloat(text.attr("dy")),
          tspan = text.text(null)
                      .append("tspan")
                      .attr("x", x)
                      .attr("y", y)
                      .attr("dy", dy + "em");
      while (word = words.shift()) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width) {
              line.pop();
              if (lineNumber == 1) {
                tspan.text(line.join(" ")+'...');
                break;
              }
              tspan.text(line.join(" "));
              line = [word];
              tspan = text.append("tspan")
                          .attr("x", x)
                          .attr("y", y)
                          .attr("dy", ++lineNumber * lineHeight + dy + "em")
                          .text(word);
          }
      }
  });
}

function BarChartRace({ ADIs, economicClass, economicCategory, assets, deposits, liabilities, loans, month, year }) {
  const barSize = 54;
  const tickFormat = undefined;
  const duration = 1500;
  const durationCSS = `${duration}ms`

  const n = 10;
  const k = 1;
  let chartHeight = 511;
  let chartWidth = 793;
  const margin = { top: 0, right: 12, bottom: 12, left: 0 };
  let x = d3.scaleLinear([0, 1], [0, chartWidth]);
  let y = d3
    .scaleBand()
    .domain(d3.range(n + 1))
    .rangeRound([margin.top, margin.top + barSize * (n - 0.9)])
    .padding(0.1);

  function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }

  function names(data) {
    return new Set(data.map((d) => d.ADI));
  }

  function groups(data) {
    return new Set(data.map((d) => d.type));
  }

  function rank(value, names) {
    const data = Array.from(names, (name) => ({ name, value: value(name) }));
    data.sort((a, b) => d3.descending(a.value, b.value));
    for (let i = 0; i < data.length; i += 1) data[i].rank = Math.min(n, i);
    return data;
  }

  function datevalues(data, Metric) {
    return Array.from(
      d3.rollup(
        data,
        ([d]) => d[Metric] * 1000000,
        (d) => d.Period,
        (d) => d.ADI
      )
    )
      .map(([date, data]) => [new Date(date), data])
      .sort(([a], [b]) => d3.ascending(a, b));
  }

  function axis(svg, NewChartWidth, NewChartHeight) {
    const g = svg
      .append('g')
      .style('transform', `translateY(${chartHeight-2.5*margin.bottom}px)`)
      .attr('width', chartWidth)
      .attr('height', chartHeight);
    const gy = svg.append('g').attr('transform', `translate(0, -${2.5*margin.bottom})`);
    const ggrid = svg
      .append('g')
      .attr('class', 'xAxisGrid')
      .attr('transform',  `translate(0,${chartHeight-2.5*margin.bottom})`);

    const axis = d3
      .axisBottom(x)
      .tickFormat((d) => nFormatter(d, 1))
      .tickSizeOuter(0)
      .tickSizeInner((n + y.padding()));

    const axisGrid = d3
      .axisBottom(x)
      .tickSize(-chartHeight)
      .tickFormat('');

    const axisLeft = d3
      .axisLeft(y)
      .ticks(chartWidth / 160, tickFormat)
      .tickSizeOuter(0)
      .tickSizeInner((n + y.padding()));
    const fontSize = NewChartWidth < mobileWidth || NewChartHeight < minHeight ? "8px" : "12px";
    return (_, transition) => {
      g.transition(transition).call(axis);
      g.select('.tick:first-of-type').remove();
      g.selectAll('.tick line').attr('stroke', '#D36B28');
      g.selectAll('.tick text').attr('color', '#D36B28').attr('font-size', fontSize).attr('font-weight', '400').attr('font-family', 'Urbanist');
      g.select('.domain').attr('stroke', '#D36B28');
      
      gy.transition(transition).call(axisLeft);
      gy.selectAll('.tick').remove();

      ggrid.transition(transition).call(axisGrid);
      ggrid.selectAll('.tick line').style('stroke-dasharray', ('3,3')).attr('stroke', 'rgba(211, 107, 40, 0.2);');
      ggrid.select('.domain').remove();
    };
  }

  const formatNumber = d3.format(',d');

  function textTween(a, b) {
    const i = d3.interpolateNumber(a, b);
    return function (t) {
      this.textContent = formatNumber(i(t));
    };
  }

  let running = false;
  if (running) {
    d3.select('#playImg')
      .attr('src', '/static/icons/pauseButton.svg');
  } else {
    d3.select('#playImg')
      .attr('src', '/static/icons/playButton.svg');
  }
  let datasetRef = assets;
  switch(economicCategory) {
    case "deposits":
      datasetRef = deposits;
      break;
    case "liabilities":
      datasetRef = liabilities;
      break;
    case "loans":
      datasetRef = loans;
      break;
    default:
      datasetRef = assets;
  }

  // Get rows where name change event field = name
  const datasetNameChanges = datasetRef.filter((d) => d.eventType === "name" && (ADIs.indexOf(d.ADI) !== -1 || ADIs.indexOf(d.eventInfo) !== -1))
  const nameChanges = [];
  datasetNameChanges.forEach((d) => {
    nameChanges.push({old:d.ADI, new:d['eventInfo'], date:d['Period']});
    if (ADIs) {
      if (ADIs.indexOf(d['eventInfo']) === -1) {
        ADIs.push(d['eventInfo'])
      } 
      if (ADIs.indexOf(d.ADI) === -1) {
        ADIs.push(d.ADI)
      }
    }
  })
  let numADIs = 10;
  if (ADIs.length > 0) {
    datasetRef = datasetRef.filter((d) => ADIs.indexOf(d.ADI) !== -1)
    numADIs = ADIs.length;
  } else {
    datasetRef = datasetRef.filter((d) => d.type === "Big4");
  }
  let dataset = JSON.parse(JSON.stringify(datasetRef));
  dataset.forEach((d) => {
    const nameEvent = nameChanges.find((x) => x.old === d.ADI);
    if (nameEvent) {
      d.ADI = nameEvent.new;
    }
  })
  let startingState = 0;
  if (month && year) {
    function addLeadingZeros(num, totalLength) {
      return String(num).padStart(totalLength, '0');
    }
    const stringToSearch = `${year}${addLeadingZeros(month,2)}`;
    startingState = [...new Set(dataset.map((d) => {
      const dateString = d.Period;
      const dateYear = parseInt(d.Period);
      const dateMonth = addLeadingZeros(parseInt(dateString.substring(dateString.indexOf("-")+1, dateString.lastIndexOf("-"))),2);
      return `${dateYear}${dateMonth}`}
    ))].sort((a,b) => a - b)
    startingState = startingState.indexOf(stringToSearch);
    /*dataset = dataset.filter((d) => { 
      //Filter so period is > input month and year
      const dateString = d.Period;
      const dateYear = parseInt(d.Period);
      const dateMonth = parseInt(dateString.substring(dateString.indexOf("-")+1, dateString.lastIndexOf("-")));
      if (dateYear < year) {
        return false;
      } else if (dateYear == year && dateMonth < month) {
        return false;
      } else {
        return true;
      }
    })
    */
  }
  
  const colors = ["#D36B28",  "#1E6685", "#CD5059", "#D38228", "#745286", "#57AB9A", "#2F4858", "#005343", "#A94C7B", "#823300"];
  const color = d3.scaleOrdinal(colors.slice(0, numADIs));
  const timelineID = "#timeline";
  const ref = useD3((svgOuter) => {

    const reDrawWidth = parseInt(d3.select("#App-header").style('width'))
    const reDrawHeight = parseInt(svgOuter.style("height"))
    let width = reDrawWidth;
    let height = reDrawHeight;
    redraw(0.92*width, height);
    function redraw(NewChartWidth, NewChartHeight) {
      chartWidth = 0.8*NewChartWidth;
      chartHeight = NewChartHeight;
      x = d3.scaleLinear([0, 1], [0, chartWidth]);
      y = d3
        .scaleBand()
        .domain(d3.range(n+ 1))
        .rangeRound([0, chartHeight])
        .padding(0.1);
        
      // const height = 530;
      // const width =  1092;
      svgOuter.selectAll('*').remove();
      const yTranslation = NewChartWidth < mobileWidth || NewChartHeight < minHeight ? 15 : 15;
      const svg = svgOuter
        .append('svg')
        .attr('y', yTranslation)
        .attr('width', NewChartWidth)
        .attr('height', NewChartHeight);
      let keyframes = [];
      let ka;
      let a;
      let kb;
      let b;
      const namesList = names(dataset);

      let updateBars;
      let updateAxis;
      let updateLabels;
      let updateTicker;
      let updateStatic;
      let updateStaticTicks;
      let state = 0;
      let Metric = 'Total residents assets';
      if (economicClass) {
        Metric = economicClass;
      }
      let MetricType = 'assets';
      if (economicCategory) {
        MetricType = economicCategory;
      }
      let groupsList = groups(dataset);
      let datasetFilter = dataset;

      function update(datasetFilter) {
        keyframes = [];
        groupsList = groups(datasetFilter);
        for ([[ka, a], [kb, b]] of d3.pairs(datevalues(datasetFilter, Metric))) {
          for (let i = 0; i < k; i += 1) {
            const t = i / k;
            keyframes.push([
              new Date(ka * (1 - t) + kb * t),
              rank((name) => (a.get(name) || 0) * (1 - t) + (b.get(name) || 0) * t, namesList)
            ]);
          }
        }
        keyframes.push([new Date(kb), rank((name) => b.get(name) || 0, namesList)]);
        const nameframes = d3.groups(
          keyframes.flatMap(([, data]) => data),
          (d) => d.name
        );
        const prev = new Map(nameframes.flatMap(([, data]) => d3.pairs(data, (a, b) => [b, a])));
        const next = new Map(nameframes.flatMap(([, data]) => d3.pairs(data)));

        function bars(svg) {
          let bar = svg
            .append('g')
            .attr('fill-opacity', 0.8)
            .selectAll('rect');

          return ([date, data], transition) => {
            (bar = bar
              .data(data.slice(0, n), (d) => d.name)
              .join(
                (enter) =>
                  enter
                    .append('rect')
                    .attr('fill', (d) => color(d))
                    .attr('height', y.bandwidth() / 1.2)
                    .attr('x', x(0))
                    .attr('y', (d) => y((prev.get(d) || d).rank))
                    .attr('width', (d) => x((prev.get(d) || d).value) - x(0)),
                (update) => update,
                (exit) =>
                  exit
                    .transition(transition)
                    .remove()
                    .attr('y', (d) => y((next.get(d) || d).rank))
                    .attr('width', (d) => x((next.get(d) || d).value) - x(0))
              )
              .call((bar) =>
                bar
                  .transition(transition)
                  .attr('y', (d) => y(d.rank))
                  .attr('width', (d) => x(d.value) - x(0))
              ));
          }
        }

        function staticLabels(svgOuter) {
          let legend = svgOuter
            .append('g')
            .attr('text-anchor', 'start')
            .selectAll('text')
          const fontSize = NewChartWidth < mobileWidth || NewChartHeight < minHeight ? "8px" : "14px";
          const legendOffset = NewChartWidth < mobileWidth || NewChartHeight < minHeight ? 13 : 41;
          return ([date, data], transition) =>
            (legend = legend
              .data(data.slice(0, n), (d) => d.name)
              .join(
                (enter) =>
                  enter
                    .append('text')
                    .attr(
                      'transform',
                      (d) => `translate(0,${y((prev.get(d) || d).rank)})`
                    )
                    .attr('y', y.bandwidth() / 2)
                    .attr('font-size', fontSize)
                    .attr('x', chartWidth+legendOffset)
                    .attr('fill', (d) => color(d))
                    .attr('dy', '0em')
                    .text((d) => d.name)
                    .call(wrap, 0.15*NewChartWidth),
                (update) => update,
                (exit) =>
                  exit
                    .transition(transition)
                    .remove()
                    .attr(
                      'transform',
                      (d) => `translate(0,${y((next.get(d) || d).rank)})`
                    )
              )
              .call((bar) =>
                bar
                  .transition(transition)
                  .attr('transform', (d) => `translate(0,${y(d.rank)})`)
              ));
        }     
        function staticLabelsTicks(svgOuter) {
          let legend = svgOuter
            .append('g')
            .attr('text-anchor', 'start')
            .selectAll('line')
            
          const tickLength = NewChartWidth < mobileWidth || NewChartHeight < minHeight ?  4 : 11;
          const tickOffset = NewChartWidth < mobileWidth || NewChartHeight < minHeight ? 5 : 17;
          return ([date, data]) =>
            (legend = legend
              .data(data.slice(0, n), (d) => d.name)
              .join(
                (enter) =>
                  enter
                    .append('line')
                    .style('stroke', '#5E5F5F')
                    .attr('y1', y.bandwidth() / 2.2)
                    .attr('y2', y.bandwidth() / 2.2)
                    .attr('x1', 0)
                    .attr('x2', tickLength)
                    .attr(
                      'transform',
                      (d) => `translate(${chartWidth+tickOffset},${y((d).rank)})`
                    )
                )
              );
        }     

        function labels(svg) {
          let label = svg
            .append('g')
            // .style('font', 'bold 12px var(--sans-serif)')
            // .style('font-variant-numeric', 'tabular-nums')
            .attr('text-anchor', 'end')
            .selectAll('text');
          const labelSize = NewChartWidth < mobileWidth || NewChartHeight < minHeight ? "8px" : "14px";
          const xCoordLimit = NewChartWidth < mobileWidth || NewChartHeight < minHeight ? 70 : 115;  
          return ([date, data], transition) =>
            (label = label
              .data(data.slice(0, n), (d) => d.name)
              .join(
                (enter) =>
                  enter
                    .append('text')
                    .attr(
                      'transform',
                      (d) => `translate(${x((prev.get(d) || d).value)},${y((prev.get(d) || d).rank)})`
                    )
                    .attr('y', y.bandwidth() / 2)
                    .attr('x', 60)
                    .attr('fill',  (d) => color(d))
                    .attr('dy', '-0.25em')
                    .call((text) =>
                      text
                        .append('tspan')
                        .attr('fill-opacity', 1)
                        .attr('font-weight', '500')
                        .attr('font-size', labelSize)
                        .attr('x', (d) => {
                          const xCoord = x(d.value);
                          if (xCoord > xCoordLimit) {
                            return -8;
                          } else {
                            return xCoordLimit;
                          }
                        })
                        .attr('dy', '0.15em')
                        .attr('fill', (d) => {                          
                          const xCoord = x(d.value);
                          if (xCoord > xCoordLimit) {
                            return '#FFF'
                          } else {
                            color(d);
                          }
                        })
                    ),
                (update) => update,
                (exit) =>
                  exit
                    .transition(transition)
                    .remove()
                    .attr(
                      'transform',
                      (d) => `translate(${x((next.get(d) || d).value)},${y((next.get(d) || d).rank)})`
                    )
                    .call((g) =>
                      g
                        .select('tspan')
                        .tween('text', (d) => textTween(d.value, (next.get(d) || d).value))
                    )
              )
              .call((bar) =>
                bar
                  .transition(transition)
                  .attr('transform', (d) => `translate(${x(d.value)},${y(d.rank)})`)
                  .call((g) =>
                    g
                      .select('tspan')
                      .attr('x', (d) => {
                        const xCoord = x(d.value);
                        if (xCoord > xCoordLimit) {
                          return -8;
                        } else {
                          return xCoordLimit;
                        }
                      })
                      .attr('fill', (d) => {                          
                        const xCoord = x(d.value);
                        if (xCoord > xCoordLimit) {
                          return '#FFF'
                        } else {
                          color(d);
                        }
                      })
                      .tween('text', (d) => textTween((prev.get(d) || d).value, d.value))
                  )
              ));
        }

        const formatDate = d3.utcFormat('%b %y');

        function ticker(svg) {
          const fontSize = NewChartWidth < mobileWidth || NewChartHeight < minHeight ? "11px" : "18px";
          const now = svg
            .append('text')
            .style('font-family', 'Urbanist')
            .style('font-style', 'normal')
            .style('font-size', fontSize)
            .style('font-weight', '600')
            .style('fill', '#5E5F5F')
            .attr('x', 0)
            .attr('y', 10)
            .text(formatDate(keyframes[0][0]));

          return ([date], transition) => {
            transition.end().then(() => {
              now.text(formatDate(date));
            });
          };
        }

        updateBars = bars(svg);
        updateAxis = axis(svg, NewChartWidth, NewChartHeight);
        updateLabels = labels(svg);
        updateTicker = ticker(svgOuter);
        updateStatic = staticLabels(svg);
        updateStaticTicks = staticLabelsTicks(svg);
      }
      async function reset(stateIn, slider = false, durationReset = 200) {
        const formatDate = d3.utcFormat('%b %y');
        const formatYear = d3.utcFormat('%Y');
        const formatMonth = d3.utcFormat('%b');
        state = stateIn;
        running = false;
        d3.select('#playImg')
          .attr('src', '/static/icons/playButton.svg');
        const spans = d3.select(timelineID).selectChildren();
        const width = (state / keyframes.length) * 100;
        if (!slider) {
          d3.select(spans._groups[0][1]).style('transition-duration', `${durationReset}ms`).style('transition-timing-function', 'linear').style('width', `${width}%`);
          d3.select(spans._groups[0][2]).style('transition-duration', `${durationReset}ms`).style('transition-timing-function', 'linear').style('left', `${width}%`);
        }
        if (typeof keyframes[state] !== "undefined") {
          d3.select(d3.select(spans._groups[0][2]).selectChildren()._groups[0][1]).selectChild().selectChild().text(formatDate(keyframes[state][0]));
          d3.select('#monthText').text(formatMonth(keyframes[state][0]));
          d3.select('#yearText').text(formatYear(keyframes[state][0]));
        }
        if (keyframes[state]) {
          const keyframe = keyframes[state];
          const transition = svg.transition().duration(durationReset).ease(d3.easeLinear);
          x.domain([0, keyframe[1][0].value]);
          updateAxis(keyframe, transition);
          updateBars(keyframe, transition);
          updateLabels(keyframe, transition);
          updateTicker(keyframe, transition);
          updateStatic(keyframe, transition);
          updateStaticTicks(keyframe, transition);
          
          try {
            await transition.end();
          } catch(err) {
          }
        }
      }

      reset(startingState);

      async function makeALoopWait() {
        const formatDate = d3.utcFormat('%b %y');
        const formatYear = d3.utcFormat('%Y');
        const formatMonth = d3.utcFormat('%b');
        while (state < keyframes.length) {
          const spans = d3.select(timelineID).selectChildren();
          const width = (state / keyframes.length) * 100;
          d3.select(spans._groups[0][1]).style('transition-duration', durationCSS).style('transition-timing-function', 'linear').style('width', `${width}%`);
          d3.select(spans._groups[0][2]).style('transition-duration', durationCSS).style('transition-timing-function', 'linear').style('left', `${width}%`);
          d3.select(d3.select(spans._groups[0][2]).selectChildren()._groups[0][1]).selectChild().selectChild().text(formatDate(keyframes[state][0]));
          d3.select('#monthText').text(formatMonth(keyframes[state][0]));
          d3.select('#yearText').text(formatYear(keyframes[state][0]));
          state += 1;
          const keyframe = keyframes[state];
          const transition = svg.transition().duration(duration).ease(d3.easeLinear);
          if (keyframe) {
            x.domain([0, keyframe[1][0].value]);
            updateAxis(keyframe, transition);
            updateBars(keyframe, transition);
            updateLabels(keyframe, transition);
            updateTicker(keyframe, transition);
            updateStatic(keyframe, transition);
            // updateStaticTicks(keyframe, transition);
            await transition.end();
          }
        }
        const spans = d3.select(timelineID).selectChildren();
        const width = (state / keyframes.length) * 100;
        d3.select(spans._groups[0][1]).style('transition-duration', durationCSS).style('transition-timing-function', 'linear').style('width', `${width}%`);
        d3.select(spans._groups[0][2]).style('transition-duration', durationCSS).style('transition-timing-function', 'linear').style('left', `${width}%`);
        d3.select(d3.select(spans._groups[0][2]).selectChildren()._groups[0][1]).selectChild().selectChild().text(formatDate(keyframes[state][0]));
        running = false;
      }

      async function pause() {
        const keyframe = keyframes[state];
        const transition = svg.transition().duration(200).ease(d3.easeLinear);
        if (keyframe) {
          const formatDate = d3.utcFormat('%b %y');
          const formatYear = d3.utcFormat('%Y');
          const formatMonth = d3.utcFormat('%b');
          const spans = d3.select(timelineID).selectChildren();
          const width = (state / keyframes.length) * 100;
          d3.select(spans._groups[0][1]).style('transition-duration', '200ms').style('transition-timing-function', 'linear').style('width', `${width}%`);
          d3.select(spans._groups[0][2]).style('transition-duration', '200ms').style('transition-timing-function', 'linear').style('left', `${width}%`);
          if (typeof keyframes[state] !== "undefined") {
            d3.select(d3.select(spans._groups[0][2]).selectChildren()._groups[0][1]).selectChild().selectChild().text(formatDate(keyframes[state][0]));
            d3.select('#monthText').text(formatMonth(keyframes[state][0]));
            d3.select('#yearText').text(formatYear(keyframes[state][0]));
          }
          x.domain([0, keyframe[1][0].value]);
          updateAxis(keyframe, transition);
          updateBars(keyframe, transition);
          updateLabels(keyframe, transition);
          updateTicker(keyframe, transition);
          updateStatic(keyframe, transition);
          // updateStaticTicks(keyframe, transition);
          await transition.end();
        }
      }

      d3.select('#start').on('click', () => {
        !running ? makeALoopWait() : pause();
        running = !running;
        if (running) {
          d3.select('#playImg')
            .attr('src', '/static/icons/pauseButton.svg');
        } else {
          d3.select('#playImg')
            .attr('src', '/static/icons/playButton.svg');
        }
      });
      d3.select('#reset').on('click', () => {
        reset(startingState);
      });
      d3.select('#pause').on('click', () => pause());
      // makeALoopWait();
      
      update(datasetFilter);
      reset(startingState);
      const spans = d3.select(timelineID).selectChildren();
      const width_timeline = (startingState / [...new Set(datasetFilter.map((d) => d.Period))].length) * 100;
      d3.select(spans._groups[0][1]).style('width', `${width_timeline}%`);
      d3.select(spans._groups[0][2]).style('left', `${width_timeline}%`);
      const changeEventParent = spans;

      const change = (transition) => {
        const value = d3.select(d3.select(spans._groups[0][2]).selectChildren()._groups[0][0]).attr("value")
        state = parseInt(value-3);
        reset(state, true, transition)
      }
      d3.select(timelineID).on('mouseup', function() {
        change(200);
      });
      changeEventParent.on("mousedown", function() {
        // Get new value
        // Caught mousedown...
        changeEventParent.on("mousemove", change);
      });
      d3.select(document).on("mouseup", function() {
        changeEventParent.on("mousemove", null);
      });
    }

    d3.select(window).on('resize', function() {
      const reDrawWidth = parseInt(d3.select("#App-header").style('width'))
      const reDrawHeight = parseInt(svgOuter.style("height"))
      width = reDrawWidth;
      height = reDrawHeight;
      redraw(0.92*reDrawWidth, reDrawHeight);
    });
  });
  return (
    <svg
      ref={ref}
      style={{
        overflow: 'visible',
        preserveAspectRatio: 'none',
        marginRight: '0px',
        marginLeft: '0px',
        minWidth: '300px',
      }}
    >
      <g className="plot-area" />
      <g className="x-axis" />
      <g className="y-axis" />
    </svg>
  );
}
export default BarChartRace;
