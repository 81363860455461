import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
// ----------------------------------------------------------------------

const sidebarConfig = [
  {
    title: 'Performance',
    path: '/stats/Performance',
    icon: <img alt={pieChart2Fill} src="/static/icons/trending-up.svg"/>
  },
  {
    title: 'Virtual Mergers',
    path: '/stats/VirtualMerge',
    icon: <img alt={pieChart2Fill} src="/static/icons/table-merge-cells.svg"/>
  },
  {
    title: 'Learn More',
    path: '/stats/LearnMore',
    icon: <img alt={pieChart2Fill} src="/static/icons/LearnMore.svg"/>
  }
];

export default sidebarConfig;
