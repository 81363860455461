import React, { useLayoutEffect, useState } from 'react';

export function useWindowSize() {
  const [mobile, setMobile] = useState(false);
  useLayoutEffect(() => {
    function updateSize() {
        if(window.innerWidth > 739) {
            setMobile(false);
        } else {
            setMobile(true);
        }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return mobile;
}