import React, { useState, useEffect, useRef } from 'react';
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';
import { ThreeDots } from 'react-loader-spinner';
import { Auth } from 'aws-amplify';
import Dropdown, { MenuItem } from '@trendmicro/react-dropdown';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import VirtualMerge from './views/VirtualMerge';
// ----------------------------------------------------------------------
import {
  newKeysAssets,
  newKeysLiabilities,
  newKeysLoans,
  newKeysDeposits
} from '../utils/formatData';
import { compareCheck } from '../utils/formatNumber';
import '../design.css';
import { useWindowSize } from 'src/windowSize';
import LZString from 'lz-string';

function names(data, filter) {
  if (filter) {
    data = data.filter((d) => d.type === filter);
  }
  const tempSet = new Set(data.map((d) => d.ADI));
  return [...tempSet];
}

function types(data) {
  return Object.keys(data[0])
    .filter((word) => word !== 'Period')
    .filter((word) => word !== 'ADI')
    .filter((word) => word !== 'type')
    .filter((word) => word !== 'partition')
    .filter((word) => word !== 'prediction')
    .filter((word) => word !== 'undefined')
    .filter((word) => !word.includes('upper'))
    .filter((word) => !word.includes('lower'));
}

const monthsArray = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

function months(data, year = 2019) {
  data = data.filter((d) => d.Period.indexOf(year) !== -1);
  const tempSet = new Set(
    data.map((d) => {
      const dateString = d.Period;
      return parseInt(
        dateString.substring(dateString.indexOf('-') + 1, dateString.lastIndexOf('-'))
      );
    })
  );
  const array = [...tempSet].sort((a, b) => a - b);
  const arrayMonthNames = new Array();
  array.forEach((d) => {
    arrayMonthNames.push(monthsArray[d - 1]);
  });
  if (arrayMonthNames.length < 12) {
    arrayMonthNames.pop();
  }
  return arrayMonthNames;
}

function years(data) {
  const tempSet = new Set(
    data.map((d) => {
      const dateString = d.Period;
      return parseInt(dateString);
    })
  );
  const array = [...tempSet].sort((a, b) => a - b);
  return array;
}

const changeKeyObjects = (arr, replaceKeys) =>
  arr.map((item) => {
    const newItem = {};
    Object.keys(item).forEach((key) => {
      newItem[replaceKeys[key]] = item[[key]];
    });
    return newItem;
  });

const LoadingIndicator = () => {
  const { promiseInProgress } = usePromiseTracker();

  // return promiseInProgress && <h1> Hey some async callin progress! </h1>;
  return (
    promiseInProgress && (
      <div style={{ display: 'flex', height: '0px', justifyContent: 'center' }}>
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            top: '20vh'
          }}
        >
          <ThreeDots color="#cf7a43" height="100" width="100" />
        </div>
      </div>
    )
  );
};

const CustomDropdownMenu = styled(Dropdown.Menu)`
  padding-left: 16px;
  padding-top: 11px;
`;
CustomDropdownMenu.propTypes = Dropdown.Menu.propTypes;
CustomDropdownMenu.defaultProps = Dropdown.Menu.defaultProps;

const CustomDropdownMenuTimeline = styled(Dropdown.Menu)`
  width: 80px;
  padding-left: 8px;
  padding-top: 2px;
`;
CustomDropdownMenuTimeline.propTypes = Dropdown.Menu.propTypes;
CustomDropdownMenuTimeline.defaultProps = Dropdown.Menu.defaultProps;

const CustomDropdown = styled(Dropdown)``;
CustomDropdown.propTypes = Dropdown.propTypes;
CustomDropdown.defaultProps = Dropdown.defaultProps;

const CustomDropdownToggle = styled(Dropdown.Toggle)`
  border-color: white;
  background-color: white;
`;
CustomDropdownToggle.propTypes = Dropdown.Toggle.propTypes;
CustomDropdownToggle.defaultProps = Dropdown.Toggle.defaultProps;

export default function MultilineD3() {
  const [dataseta, setDataseta] = useState('');
  const [datasetlo, setDatasetlo] = useState('');
  const [datasetd, setDatasetd] = useState('');
  const [datasetl, setDatasetl] = useState('');
  const [ADIList, setADIList] = useState({});

  // Get cached data
  let assetsCached = [];
  let assetsM = JSON.parse(localStorage.getItem('assets-m'));
  try {
    assetsCached = Promise.resolve(JSON.parse(LZString.decompress(localStorage.getItem('assets'))));
  } catch (error) {
    assetsM = 0;
  }
  const assetsLastMod = assetsM ? assetsM : '1';

  let loansCached = [];
  let loansM = JSON.parse(localStorage.getItem('loans-m'));
  try {
    loansCached = Promise.resolve(JSON.parse(LZString.decompress(localStorage.getItem('loans'))));
  } catch (error) {
    loansM = 0;
  }
  const loansLastMod = loansM ? loansM : '1';

  let depositsCached = [];
  let depositsM = JSON.parse(localStorage.getItem('deposits-m'));
  try {
    depositsCached = Promise.resolve(
      JSON.parse(LZString.decompress(localStorage.getItem('deposits')))
    );
  } catch (error) {
    depositsM = 0;
  }
  const depositsLastMod = depositsM ? depositsM : '1';

  let liabilitiesCached = [];
  let liabilitiesM = JSON.parse(localStorage.getItem('liabilities-m'));
  try {
    liabilitiesCached = Promise.resolve(
      JSON.parse(LZString.decompress(localStorage.getItem('liabilities')))
    );
  } catch (error) {
    liabilitiesM = 0;
  }
  const liabilitiesLastMod = liabilitiesM ? liabilitiesM : '1';

  useEffect(() => {
    // Get cognito userpool token
    // No authentication for now
    // Auth.currentSession().then((data) => {
    trackPromise(
      Promise.all([
        fetch(
          'https://0b18hurmgf.execute-api.ap-southeast-2.amazonaws.com/dev/items?heading=assets&modified=' +
            assetsLastMod,
          {
            /*headers: {
              Authorization: `Bearer ${data.idToken.jwtToken}`
              }*/
          }
        ).then((response) => {
          let data = '';
          let lastMod = '';
          if (response.status === 200) {
            data = response.json();
            lastMod = response.headers.get('last-modified');
          } else {
            data = assetsCached;
            lastMod = assetsLastMod;
          }
          return { data, lastMod };
        }),
        fetch(
          'https://0b18hurmgf.execute-api.ap-southeast-2.amazonaws.com/dev/items?heading=loans&modified=' +
            loansLastMod,
          {
            /*headers: {
              Authorization: `Bearer ${data.idToken.jwtToken}`
              }*/
          }
        ).then((response) => {
          let data = '';
          let lastMod = '';
          if (response.status === 200) {
            data = response.json();
            lastMod = response.headers.get('last-modified');
          } else {
            data = loansCached;
            lastMod = loansLastMod;
          }
          return { data, lastMod };
        }),
        fetch(
          'https://0b18hurmgf.execute-api.ap-southeast-2.amazonaws.com/dev/items?heading=deposits&modified=' +
            depositsLastMod,
          {
            /*headers: {
              Authorization: `Bearer ${data.idToken.jwtToken}`
              }*/
          }
        ).then((response) => {
          let data = '';
          let lastMod = '';
          if (response.status === 200) {
            data = response.json();
            lastMod = response.headers.get('last-modified');
          } else {
            data = depositsCached;
            lastMod = depositsLastMod;
          }
          return { data, lastMod };
        }),
        fetch(
          'https://0b18hurmgf.execute-api.ap-southeast-2.amazonaws.com/dev/items?heading=liabilities&modified=' +
            liabilitiesLastMod,
          {
            /*headers: {
              Authorization: `Bearer ${data.idToken.jwtToken}`
               }*/
          }
        ).then((response) => {
          let data = '';
          let lastMod = '';
          if (response.status === 200) {
            data = response.json();
            lastMod = response.headers.get('last-modified');
          } else {
            data = liabilitiesCached;
            lastMod = liabilitiesLastMod;
          }
          return { data, lastMod };
        })
      ]).then(([assets, loans, deposits, liabilities]) => {
        assets.data.then((data) => {
          const lastModified = assets.lastMod;
          if (compareCheck(lastModified, assetsLastMod)) {
            const renamedData = changeKeyObjects(data, newKeysAssets).sort((a, b) =>
              a.ADI > b.ADI ? 1 : b.ADI > a.ADI ? -1 : 0
            );
            setADIList(names(renamedData));
            setDataseta(renamedData);
            localStorage.removeItem('assets');
            localStorage.removeItem('assets-m');
            localStorage.setItem('assets', LZString.compress(JSON.stringify({ data })));
            localStorage.setItem('assets-m', JSON.stringify(assets.lastMod));
          } else {
            data = Object.values(data);
            const renamedData = changeKeyObjects(data[0], newKeysAssets).sort((a, b) =>
              a.ADI > b.ADI ? 1 : b.ADI > a.ADI ? -1 : 0
            );
            setADIList(names(renamedData));
            setDataseta(renamedData);
          }
        });

        loans.data.then((data) => {
          const lastModified = loans.lastMod;
          if (compareCheck(lastModified, loansLastMod)) {
            const renamedData = changeKeyObjects(data, newKeysLoans);
            setDatasetlo(renamedData);
            localStorage.removeItem('loans');
            localStorage.removeItem('loans-m');
            localStorage.setItem('loans', LZString.compress(JSON.stringify({ data })));
            localStorage.setItem('loans-m', JSON.stringify(loans.lastMod));
          } else {
            data = Object.values(data);
            const renamedData = changeKeyObjects(data[0], newKeysLoans);
            setDatasetlo(renamedData);
          }
        });

        deposits.data.then((data) => {
          const lastModified = deposits.lastMod;
          if (compareCheck(lastModified, depositsLastMod)) {
            const renamedData = changeKeyObjects(data, newKeysDeposits);
            setDatasetd(renamedData);
            localStorage.removeItem('deposits');
            localStorage.removeItem('deposits-m');
            localStorage.setItem('deposits', LZString.compress(JSON.stringify({ data })));
            localStorage.setItem('deposits-m', JSON.stringify(deposits.lastMod));
          } else {
            data = Object.values(data);
            const renamedData = changeKeyObjects(data[0], newKeysDeposits);
            setDatasetd(renamedData);
          }
        });
        liabilities.data.then((data) => {
          const lastModified = liabilities.lastMod;
          if (compareCheck(lastModified, liabilitiesLastMod)) {
            const renamedData = changeKeyObjects(data, newKeysLiabilities);
            setDatasetl(renamedData);
            localStorage.removeItem('liabilities');
            localStorage.removeItem('liabilities-m');
            localStorage.setItem('liabilities', LZString.compress(JSON.stringify({ data })));
            localStorage.setItem('liabilities-m', JSON.stringify(liabilities.lastMod));
          } else {
            data = Object.values(data);
            const renamedData = changeKeyObjects(data[0], newKeysLiabilities);
            setDatasetl(renamedData);
          }
        });
      })
    );
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  //Economic Class
  const [isEconomicClassOpen, setIsEconomicClassOpen] = useState(false);
  const [economicClassIcon, setEconomicClassIcon] = useState('/static/icons/hand-coin-outline.svg');
  const [economicClassFontColor, setEconomicClassFontColor] = useState('#5E5F5F');
  const [economicCategory, setEconomicCategory] = useState('');
  const [economicClass, setEconomicClass] = useState('');
  const [isAssetsOpen, setIsAssetsOpen] = useState(false);
  const [isDepositsOpen, setIsDepositsOpen] = useState(false);
  const [isLiabilitiesOpen, setIsLiabilitiesOpen] = useState(false);
  const [isLoansOpen, setIsLoansOpen] = useState(false);

  //Timeline states
  const [isMonthOpen, setIsMonthOpen] = useState(false);
  const [isYearOpen, setIsYearOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState('Mar');
  const [selectedYear, setSelectedYear] = useState(2019);

  //Search bar query
  const [query, setQuery] = useState('');
  // open state for drop down boxes
  // Main menu, Top 10, Big 4, Mutual, All
  const [isInstitutionOpen, setIsInstitutionOpen] = useState(false);
  const [isInstitution2Open, setIsInstitution2Open] = useState(false);
  const [institutionOne, setInstitutionOne] = useState('');
  const [institutionTwo, setInstitutionTwo] = useState('');

  //number of selected check boxes
  const [selectedCount, setSelectedCount] = useState(0);
  const [fontColor, setFontColor] = useState('#5E5F5F');

  const [institutionIcon, setInstitutionIcon] = useState('/static/icons/bank-outline.svg');
  const [arrowIcon, setArrowIcon] = useState('/static/icons/chevron.svg');
  const [arrowIconOrange, setarrowIconOrange] = useState('/static/icons/chevron-orange.svg');
  const [checkedState, setCheckedState] = useState(new Array(150).fill(false));
  function handleResetFilters() {
    //Economic Class
    setIsEconomicClassOpen(false);
    setEconomicClassIcon('/static/icons/hand-coin-outline.svg');
    setEconomicClassFontColor('#5E5F5F');
    setEconomicClass('');
    setEconomicCategory('');
    setIsAssetsOpen(false);
    setIsDepositsOpen(false);
    setIsLiabilitiesOpen(false);
    setIsLoansOpen(false);

    //Search bar query
    setQuery('');
    // open state for drop down boxes
    //number of selected check boxes
    setSelectedCount(0);
    setFontColor('#5E5F5F');
    setInstitutionIcon('/static/icons/bank-outline.svg');
    setArrowIcon('/static/icons/chevron.svg');
    setarrowIconOrange('/static/icons/chevron-orange.svg');
    setCheckedState(new Array(150).fill(false));
  }

  function handleEconomicClassChange(event, category) {
    setEconomicClass(event.target.innerText);
    setEconomicCategory(category);
    setEconomicClassIcon('/static/icons/hand-coin-outline-orange.svg');
    setEconomicClassFontColor('#D36B28');
  }

  function handleChangeOne(event) {
    if (event) {
      setFontColor('#D36B28');
      setInstitutionIcon('/static/icons/bank-outline-orange.svg');
      setIsInstitutionOpen(false);
      setInstitutionOne(event.target.innerText);
    }
  }
  function handleChangeTwo(event) {
    if (event) {
      setFontColor('#D36B28');
      setInstitutionIcon('/static/icons/bank-outline-orange.svg');
      setIsInstitution2Open(false);
      setInstitutionTwo(event.target.innerText);
    }
  }
  function handleOnMouseEnter(
    institutions = true,
    first = false,
    second = false,
    third = false,
    fourth = false
  ) {
    if (!mobile) {
      setIsAssetsOpen(first);
      setIsDepositsOpen(second);
      setIsLiabilitiesOpen(third);
      setIsLoansOpen(fourth);
    }
  }
  let dropdownMenu = null;
  const ref = useRef();
  const refMonths = useRef();
  const refInstitution1 = useRef();
  const refInstitution2 = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isEconomicClassOpen && ref.current && !ref.current.contains(e.target)) {
        setIsEconomicClassOpen(false);
      }
      if (isYearOpen && refMonths.current && !refMonths.current.contains(e.target)) {
        setIsYearOpen(false);
      }
      if (isMonthOpen && refMonths.current && !refMonths.current.contains(e.target)) {
        setIsMonthOpen(false);
      }
      if (
        isInstitutionOpen &&
        refInstitution1.current &&
        !refInstitution1.current.contains(e.target)
      ) {
        setIsInstitutionOpen(false);
      }
      if (
        isInstitution2Open &&
        refInstitution2.current &&
        !refInstitution2.current.contains(e.target)
      ) {
        setIsInstitution2Open(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isEconomicClassOpen, isYearOpen, isMonthOpen, isInstitutionOpen, isInstitution2Open]);

  const window = useWindowSize();
  const [mobile, setMobile] = useState(window);
  useEffect(() => {
    setMobile(window);
  }, [window]);
  return (
    <main className="main">
      <div className="Frame65" style={{ marginBottom: '24px' }}>
        <text className="Performance" style={{ whiteSpace: 'nowrap' }}>
          Virtual Mergers
        </text>
        <text className="PerformanceText">
          Choose 2 different institutions and the desired economic class to simulate the financial
          performance of their merged entity.
        </text>
      </div>
      {mobile && <hr className="LineMobile" />}
      <div id="tooltip" />
      <LoadingIndicator />
      {dataseta && datasetd && datasetl && datasetlo && (
        <div className="App">
          <div className="Frame12">
            <div className="Frame11">
              <text className="Institution">Institution No. 1</text>
              <div className="Frame1" ref={refInstitution1}>
                {/*onClick={() => {setIsOpen(!isOpen); console.log(isOpen);}}*/}
                <CustomDropdown
                  open={isInstitutionOpen}
                  onClick={() => {
                    setIsInstitutionOpen(!isInstitutionOpen);
                    setIsEconomicClassOpen(false);
                    setIsInstitution2Open(false);
                    setQuery('');
                  }}
                  onSelect={(eventKey, event) => {
                    handleChangeOne(event);
                  }}
                >
                  <CustomDropdownToggle noCaret title={`Institution (${selectedCount})`}>
                    <img
                      className="bankOutline"
                      alt=""
                      src={institutionIcon}
                      height="24"
                      width="24"
                    />
                    <text
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        flex: '1',
                        position: 'relative',
                        left: '45px',
                        top: '1px',
                        color: institutionOne ? fontColor : '#5E5F5F'
                      }}
                    >
                      {institutionOne ? `${institutionOne}` : 'Institution No. 1'}
                    </text>
                    <img
                      className="arrowDropdown"
                      alt=""
                      src={fontColor === '#D36B28' ? arrowIconOrange : arrowIcon}
                      height="24"
                      width="24"
                    />
                  </CustomDropdownToggle>
                  <CustomDropdownMenu
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <div
                      style={{ pointerEvents: 'auto', cursor: 'default' }}
                      onClick={(event) => {
                        handleChangeOne(event);
                        event.preventDefault();
                        event.stopPropagation();
                      }}
                    >
                      {mobile && (
                        <div className="mobileHeadingMenu">
                          Institution No. 1
                          <button
                            id="return"
                            className="mobileButton"
                            onClick={() => {
                              setIsInstitutionOpen(false);
                            }}
                          >
                            <img src="/static/icons/arrow-left.svg" />
                          </button>
                        </div>
                      )}
                      <div className="searchBar">
                        <input
                          id="InstitutionFilter"
                          type="text"
                          placeholder="Search..."
                          className="labelInstitutionFilter"
                          onClick={(event) => event.stopPropagation()}
                          onChange={(event) => {
                            setQuery(event.target.value);
                            event.stopPropagation();
                          }}
                          value={query ? query : ''}
                        ></input>
                      </div>
                      <div className="scroll_container">
                        <div className="scroll_content">
                          <Scrollbars
                            renderTrackHorizontal={(props) => (
                              <div
                                {...props}
                                style={{ display: 'none' }}
                                className="track-horizontal"
                              />
                            )}
                            renderTrackVertical={(props) => (
                              <div {...props} className="track-vertical" />
                            )}
                            renderThumbVertical={(props) => (
                              <div {...props} className="thumb-vertical" />
                            )}
                            style={{ width: '100%' }}
                          >
                            {names(dataseta)
                              .filter((adi) => {
                                if (query === '') {
                                  return adi;
                                } else if (adi.toLowerCase().includes(query.toLowerCase())) {
                                  return adi;
                                }
                              })
                              .map((d) => {
                                const index = ADIList.indexOf(d);
                                return (
                                  <MenuItem style={{ fontWeight: '500' }} eventKey={d}>
                                    <div
                                      className="dropdownText"
                                      style={{
                                        borderRadius: '8px',
                                        background:
                                          institutionOne === d ? 'rgba(211, 107, 40, 0.1)' : '#FFF'
                                      }}
                                    >
                                      <text
                                        className="ADIText"
                                        index={index}
                                        style={{
                                          color: institutionOne === d ? '#D36B28' : '#5E5F5F',
                                          fontWeight: '500'
                                        }}
                                      >
                                        {d}
                                      </text>
                                    </div>
                                  </MenuItem>
                                );
                              })}
                          </Scrollbars>
                        </div>
                      </div>
                    </div>
                    {/* Dropdown for  handling search (invisible)*/}
                    <Dropdown.SubMenu
                      open={false}
                      style={{ pointerEvents: 'none', cursor: 'default' }}
                      ref={(node) => {
                        dropdownMenu = node;
                      }}
                    >
                      {names(dataseta).map((d) => {
                        const index = ADIList.indexOf(d);
                        return (
                          <MenuItem style={{ fontWeight: '500' }} eventKey={d}>
                            <div
                              className="dropdownText"
                              style={{
                                borderRadius:
                                  checkedState[index] &&
                                  checkedState[index + 1] &&
                                  checkedState[index - 1]
                                    ? '0px 0px 0px 0px'
                                    : checkedState[index] && checkedState[index - 1]
                                    ? '0px 0px 8px 8px'
                                    : checkedState[index] && checkedState[index + 1]
                                    ? '8px 8px 0px 0px'
                                    : '8px',
                                background: institutionOne == d ? 'rgba(211, 107, 40, 0.1)' : '#FFF'
                              }}
                            >
                              <input
                                key={d + checkedState[index]}
                                type="checkbox"
                                name={`${d}`}
                                index={index}
                                className="checkbox-neo"
                                style={{ marginRight: 5 }}
                                checked={checkedState[index]}
                                readOnly
                              />
                              <text
                                className="ADIText"
                                index={index}
                                style={{
                                  color: institutionOne == d ? '#D36B28' : '#5E5F5F',
                                  fontWeight: '500'
                                }}
                              >
                                {d}
                              </text>
                            </div>
                          </MenuItem>
                        );
                      })}
                    </Dropdown.SubMenu>
                  </CustomDropdownMenu>
                </CustomDropdown>
              </div>
            </div>
            <div className="Frame11">
              <text className="Institution">Institution No. 2</text>
              <div className="Frame1" ref={refInstitution2}>
                {/*onClick={() => {setIsOpen(!isOpen); console.log(isOpen);}}*/}
                <CustomDropdown
                  open={isInstitution2Open}
                  onClick={() => {
                    setIsInstitution2Open(!isInstitution2Open);
                    setIsEconomicClassOpen(false);
                    setIsInstitutionOpen(false);
                    setQuery('');
                  }}
                  onSelect={(eventKey, event) => {
                    handleChangeTwo(event);
                  }}
                >
                  <CustomDropdownToggle noCaret title={`Institution (${selectedCount})`}>
                    <img
                      className="bankOutline"
                      alt=""
                      src={institutionIcon}
                      height="24"
                      width="24"
                    />
                    <text
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        flex: '1',
                        position: 'relative',
                        width: '100%',
                        left: '45px',
                        top: '1px',
                        color: institutionTwo ? fontColor : '#5E5F5F'
                      }}
                    >
                      {institutionTwo ? `${institutionTwo}` : 'Institution No. 2'}
                    </text>
                    <img
                      className="arrowDropdown"
                      alt=""
                      src={fontColor === '#D36B28' ? arrowIconOrange : arrowIcon}
                      height="24"
                      width="24"
                    />
                  </CustomDropdownToggle>
                  <CustomDropdownMenu
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <div
                      style={{ pointerEvents: 'auto', cursor: 'default' }}
                      onClick={(event) => {
                        handleChangeTwo(event);
                        event.preventDefault();
                        event.stopPropagation();
                      }}
                    >
                      {mobile && (
                        <div className="mobileHeadingMenu">
                          Institution No. 2
                          <button
                            id="return"
                            className="mobileButton"
                            onClick={() => {
                              setIsInstitution2Open(false);
                            }}
                          >
                            <img src="/static/icons/arrow-left.svg" />
                          </button>
                        </div>
                      )}
                      <div className="searchBar">
                        <input
                          id="InstitutionFilter"
                          type="text"
                          placeholder="Search..."
                          className="labelInstitutionFilter"
                          onClick={(event) => event.stopPropagation()}
                          onChange={(event) => setQuery(event.target.value)}
                          value={query ? query : ''}
                        ></input>
                      </div>
                      <div className="scroll_container">
                        <div className="scroll_content">
                          <Scrollbars
                            renderTrackHorizontal={(props) => (
                              <div
                                {...props}
                                style={{ display: 'none' }}
                                className="track-horizontal"
                              />
                            )}
                            renderTrackVertical={(props) => (
                              <div {...props} className="track-vertical" />
                            )}
                            renderThumbVertical={(props) => (
                              <div {...props} className="thumb-vertical" />
                            )}
                            style={{ width: '100%' }}
                          >
                            {names(dataseta)
                              .filter((adi) => {
                                if (query === '') {
                                  return adi;
                                } else if (adi.toLowerCase().includes(query.toLowerCase())) {
                                  return adi;
                                }
                              })
                              .map((d) => {
                                const index = ADIList.indexOf(d);
                                return (
                                  <MenuItem style={{ fontWeight: '500' }} eventKey={d}>
                                    <div
                                      className="dropdownText"
                                      style={{
                                        borderRadius: '8px',
                                        background:
                                          institutionTwo === d ? 'rgba(211, 107, 40, 0.1)' : '#FFF'
                                      }}
                                    >
                                      <text
                                        className="ADIText"
                                        index={index}
                                        style={{
                                          color: institutionTwo === d ? '#D36B28' : '#5E5F5F',
                                          fontWeight: '500'
                                        }}
                                      >
                                        {d}
                                      </text>
                                    </div>
                                  </MenuItem>
                                );
                              })}
                          </Scrollbars>
                        </div>
                      </div>
                    </div>
                  </CustomDropdownMenu>
                </CustomDropdown>
              </div>
            </div>
            {/* Economic Class */}
            <div className="Frame11" style={{ width: '420px' }}>
              <text className="Institution">Economic Class</text>
              <div className="Frame1_2" ref={ref}>
                {/*onClick={() => {setIsOpen(!isOpen); console.log(isOpen);}}*/}
                <CustomDropdown
                  open={isEconomicClassOpen}
                  onClick={() => {
                    setIsEconomicClassOpen(!isEconomicClassOpen);
                    setIsInstitutionOpen(false);
                    setIsInstitution2Open(false);
                    setIsAssetsOpen(false);
                    setIsDepositsOpen(false);
                    setIsLiabilitiesOpen(false);
                    setIsLoansOpen(false);
                  }}
                >
                  <CustomDropdownToggle noCaret title={''} style={{ width: '420px' }}>
                    <img
                      className="bankOutline"
                      alt=""
                      src={economicClassIcon}
                      height="24"
                      width="24"
                    />
                    <text
                      style={{
                        position: 'relative',
                        left: '45px',
                        top: '1px',
                        color: economicClassFontColor
                      }}
                    >
                      {economicClass ? `${economicClass}` : 'Economic Class'}
                    </text>
                    <img
                      className="arrowDropdown"
                      alt=""
                      src={economicClassFontColor === '#D36B28' ? arrowIconOrange : arrowIcon}
                      height="24"
                      width="24"
                    />
                  </CustomDropdownToggle>
                  <CustomDropdownMenu
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <MenuItem
                      onClick={(event) => {
                        setIsAssetsOpen(!isAssetsOpen);
                        setIsDepositsOpen(false);
                        setIsLiabilitiesOpen(false);
                        setIsLoansOpen(false);
                        event.preventDefault();
                        event.stopPropagation();
                      }}
                      style={{ cursor: 'default', paddingLeft: '0px' }}
                      eventKey="Big 4"
                    >
                      <div
                        className="dropdownHeading"
                        onMouseEnter={() => {
                          handleOnMouseEnter(false, true, false, false, false);
                        }}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          height: '32px'
                        }}
                      >
                        <text
                          style={{
                            color: isAssetsOpen ? '#D36B28' : '#5E5F5F',
                            fontWeight: '600',
                            position: 'relative',
                            width: '130px',
                            paddingLeft: '8px'
                          }}
                        >
                          Assets
                        </text>
                        <img
                          className="arrowSubMenu"
                          alt=""
                          src={isAssetsOpen ? arrowIconOrange : arrowIcon}
                          height="24"
                          width="24"
                        />
                      </div>
                      <Dropdown.SubMenu
                        open={isAssetsOpen}
                        style={{ pointerEvents: 'none', cursor: 'default' }}
                      >
                        <div
                          style={{ pointerEvents: 'none', cursor: 'default', width: '330px' }}
                          onClick={(event) => {
                            handleEconomicClassChange(event, 'assets');
                            setIsEconomicClassOpen(false);
                            event.preventDefault();
                            event.stopPropagation();
                          }}
                        >
                          {mobile && (
                            <div className="mobileHeadingMenu">
                              Assets
                              <button
                                id="return"
                                className="mobileButton"
                                onClick={() => {
                                  setIsAssetsOpen(false);
                                }}
                              >
                                <img src="/static/icons/arrow-left.svg" />
                              </button>
                            </div>
                          )}
                          <div
                            className="scroll_container"
                            style={{ pointerEvents: 'auto', height: '270px' }}
                          >
                            <div className="scroll_content">
                              <Scrollbars
                                renderTrackHorizontal={(props) => (
                                  <div
                                    {...props}
                                    style={{ display: 'none' }}
                                    className="track-horizontal"
                                  />
                                )}
                                renderTrackVertical={(props) => (
                                  <div {...props} className="track-vertical" />
                                )}
                                renderThumbVertical={(props) => (
                                  <div {...props} className="thumb-vertical" />
                                )}
                                style={{ width: '100%' }}
                              >
                                {types(dataseta).map((d) => {
                                  const index = ADIList.indexOf(d);
                                  return (
                                    <MenuItem style={{ fontWeight: '500' }} eventKey={d}>
                                      <div
                                        className="dropdownText"
                                        style={{
                                          borderRadius: '8px',
                                          background:
                                            economicClass === d ? 'rgba(211, 107, 40, 0.1)' : '#FFF'
                                        }}
                                      >
                                        <text
                                          className="ADIText"
                                          index={index}
                                          style={{
                                            color: economicClass === d ? '#D36B28' : '#5E5F5F',
                                            fontWeight: '500'
                                          }}
                                        >
                                          {d}
                                        </text>
                                      </div>
                                    </MenuItem>
                                  );
                                })}
                              </Scrollbars>
                            </div>
                          </div>
                        </div>
                      </Dropdown.SubMenu>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        setIsAssetsOpen(false);
                        setIsDepositsOpen(!isDepositsOpen);
                        setIsLiabilitiesOpen(false);
                        setIsLoansOpen(false);
                        event.preventDefault();
                        event.stopPropagation();
                      }}
                      style={{ cursor: 'default', paddingLeft: '0px' }}
                    >
                      <div
                        className="dropdownHeading"
                        onMouseEnter={() => {
                          handleOnMouseEnter(false, false, true, false, false);
                        }}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          height: '32px'
                        }}
                      >
                        <text
                          style={{
                            color: isDepositsOpen ? '#D36B28' : '#5E5F5F',
                            fontWeight: '600',
                            position: 'relative',
                            width: '130px',
                            paddingLeft: '8px'
                          }}
                        >
                          Deposits
                        </text>
                        <img
                          className="arrowSubMenu"
                          alt=""
                          src={isDepositsOpen ? arrowIconOrange : arrowIcon}
                          height="24"
                          width="24"
                        />
                      </div>
                      <Dropdown.SubMenu
                        open={isDepositsOpen}
                        style={{ pointerEvents: 'none', cursor: 'default' }}
                      >
                        <div
                          style={{ pointerEvents: 'none', cursor: 'default', width: '270px' }}
                          onClick={(event) => {
                            handleEconomicClassChange(event, 'deposits');
                            setIsEconomicClassOpen(false);
                            event.preventDefault();
                            event.stopPropagation();
                          }}
                        >
                          {mobile && (
                            <div className="mobileHeadingMenu">
                              Deposits
                              <button
                                id="return"
                                className="mobileButton"
                                onClick={() => {
                                  setIsDepositsOpen(false);
                                }}
                              >
                                <img src="/static/icons/arrow-left.svg" />
                              </button>
                            </div>
                          )}
                          <div
                            className="scroll_container"
                            style={{ pointerEvents: 'auto', height: '240px' }}
                          >
                            <div className="scroll_content">
                              <Scrollbars
                                renderTrackHorizontal={(props) => (
                                  <div
                                    {...props}
                                    style={{ display: 'none' }}
                                    className="track-horizontal"
                                  />
                                )}
                                renderTrackVertical={(props) => (
                                  <div {...props} className="track-vertical" />
                                )}
                                renderThumbVertical={(props) => (
                                  <div {...props} className="thumb-vertical" />
                                )}
                                style={{ width: '100%' }}
                              >
                                {types(datasetd).map((d) => {
                                  const index = ADIList.indexOf(d);
                                  return (
                                    <MenuItem style={{ fontWeight: '500' }} eventKey={d}>
                                      <div
                                        className="dropdownText"
                                        style={{
                                          borderRadius: '8px',
                                          background:
                                            economicClass === d ? 'rgba(211, 107, 40, 0.1)' : '#FFF'
                                        }}
                                      >
                                        <text
                                          className="ADIText"
                                          index={index}
                                          style={{
                                            color: economicClass === d ? '#D36B28' : '#5E5F5F',
                                            fontWeight: '500'
                                          }}
                                        >
                                          {d}
                                        </text>
                                      </div>
                                    </MenuItem>
                                  );
                                })}
                              </Scrollbars>
                            </div>
                          </div>
                        </div>
                      </Dropdown.SubMenu>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        setIsAssetsOpen(false);
                        setIsDepositsOpen(false);
                        setIsLiabilitiesOpen(!isLiabilitiesOpen);
                        setIsLoansOpen(false);
                        event.preventDefault();
                        event.stopPropagation();
                      }}
                      style={{ cursor: 'default', paddingLeft: '0px' }}
                    >
                      <div
                        className="dropdownHeading"
                        onMouseEnter={() => {
                          handleOnMouseEnter(false, false, false, true, false);
                        }}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          height: '32px'
                        }}
                      >
                        <text
                          style={{
                            color: isLiabilitiesOpen ? '#D36B28' : '#5E5F5F',
                            fontWeight: '600',
                            position: 'relative',
                            width: '130px',
                            paddingLeft: '8px'
                          }}
                        >
                          Liabilities
                        </text>
                        <img
                          className="arrowSubMenu"
                          alt=""
                          src={isLiabilitiesOpen ? arrowIconOrange : arrowIcon}
                          height="24"
                          width="24"
                        />
                      </div>
                      <Dropdown.SubMenu
                        open={isLiabilitiesOpen}
                        style={{ pointerEvents: 'none', cursor: 'default' }}
                      >
                        <div
                          style={{ pointerEvents: 'none', cursor: 'default', width: '270px' }}
                          onClick={(event) => {
                            handleEconomicClassChange(event, 'liabilities');
                            setIsEconomicClassOpen(false);
                            event.preventDefault();
                            event.stopPropagation();
                          }}
                        >
                          {mobile && (
                            <div className="mobileHeadingMenu">
                              Liabilities
                              <button
                                id="return"
                                className="mobileButton"
                                onClick={() => {
                                  setIsLiabilitiesOpen(false);
                                }}
                              >
                                <img src="/static/icons/arrow-left.svg" />
                              </button>
                            </div>
                          )}
                          <div
                            className="scroll_container"
                            style={{ pointerEvents: 'auto', height: '240px' }}
                          >
                            <div className="scroll_content">
                              <Scrollbars
                                renderTrackHorizontal={(props) => (
                                  <div
                                    {...props}
                                    style={{ display: 'none' }}
                                    className="track-horizontal"
                                  />
                                )}
                                renderTrackVertical={(props) => (
                                  <div {...props} className="track-vertical" />
                                )}
                                renderThumbVertical={(props) => (
                                  <div {...props} className="thumb-vertical" />
                                )}
                                style={{ width: '100%' }}
                              >
                                {types(datasetl).map((d) => {
                                  const index = ADIList.indexOf(d);
                                  return (
                                    <MenuItem style={{ fontWeight: '500' }} eventKey={d}>
                                      <div
                                        className="dropdownText"
                                        style={{
                                          borderRadius: '8px',
                                          background:
                                            economicClass === d ? 'rgba(211, 107, 40, 0.1)' : '#FFF'
                                        }}
                                      >
                                        <text
                                          className="ADIText"
                                          index={index}
                                          style={{
                                            color: economicClass === d ? '#D36B28' : '#5E5F5F',
                                            fontWeight: '500'
                                          }}
                                        >
                                          {d}
                                        </text>
                                      </div>
                                    </MenuItem>
                                  );
                                })}
                              </Scrollbars>
                            </div>
                          </div>
                        </div>
                      </Dropdown.SubMenu>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        setIsAssetsOpen(false);
                        setIsDepositsOpen(false);
                        setIsLiabilitiesOpen(false);
                        setIsLoansOpen(!isLoansOpen);
                        event.preventDefault();
                        event.stopPropagation();
                      }}
                      style={{ cursor: 'default', paddingLeft: '0px' }}
                    >
                      <div
                        className="dropdownHeading"
                        onMouseEnter={() => {
                          handleOnMouseEnter(false, false, false, false, true);
                        }}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          height: '32px'
                        }}
                      >
                        <text
                          style={{
                            color: isLoansOpen ? '#D36B28' : '#5E5F5F',
                            fontWeight: '600',
                            position: 'relative',
                            width: '130px',
                            paddingLeft: '8px'
                          }}
                        >
                          Loans
                        </text>
                        <img
                          className="arrowSubMenu"
                          alt=""
                          src={isLoansOpen ? arrowIconOrange : arrowIcon}
                          height="24"
                          width="24"
                        />
                      </div>
                      <Dropdown.SubMenu
                        open={isLoansOpen}
                        style={{ pointerEvents: 'none', cursor: 'default' }}
                      >
                        <div
                          style={{ pointerEvents: 'none', cursor: 'default', width: '300px' }}
                          onClick={(event) => {
                            handleEconomicClassChange(event, 'loans');
                            setIsEconomicClassOpen(false);
                            event.preventDefault();
                            event.stopPropagation();
                          }}
                        >
                          {mobile && (
                            <div className="mobileHeadingMenu">
                              Loans
                              <button
                                id="return"
                                className="mobileButton"
                                onClick={() => {
                                  setIsLoansOpen(false);
                                }}
                              >
                                <img src="/static/icons/arrow-left.svg" />
                              </button>
                            </div>
                          )}
                          <div
                            className="scroll_container"
                            style={{ pointerEvents: 'auto', height: '390px' }}
                          >
                            <div className="scroll_content">
                              <Scrollbars
                                renderTrackHorizontal={(props) => (
                                  <div
                                    {...props}
                                    style={{ display: 'none' }}
                                    className="track-horizontal"
                                  />
                                )}
                                renderTrackVertical={(props) => (
                                  <div {...props} className="track-vertical" />
                                )}
                                renderThumbVertical={(props) => (
                                  <div {...props} className="thumb-vertical" />
                                )}
                                style={{ width: '100%' }}
                              >
                                {types(datasetlo).map((d) => {
                                  const index = ADIList.indexOf(d);
                                  return (
                                    <MenuItem style={{ fontWeight: '500' }} eventKey={d}>
                                      <div
                                        className="dropdownText"
                                        style={{
                                          borderRadius: '8px',
                                          background:
                                            economicClass === d ? 'rgba(211, 107, 40, 0.1)' : '#FFF'
                                        }}
                                      >
                                        <text
                                          className="ADIText"
                                          index={index}
                                          style={{
                                            color: economicClass === d ? '#D36B28' : '#5E5F5F',
                                            fontWeight: '500'
                                          }}
                                        >
                                          {d}
                                        </text>
                                      </div>
                                    </MenuItem>
                                  );
                                })}
                              </Scrollbars>
                            </div>
                          </div>
                        </div>
                      </Dropdown.SubMenu>
                    </MenuItem>
                  </CustomDropdownMenu>
                </CustomDropdown>
              </div>
            </div>
            <div className="Frame6">
              <button
                className="ResetFilters"
                onClick={(event) => {
                  handleResetFilters(event);
                }}
              >
                Reset filters
              </button>
            </div>
          </div>
          <div className="graphBackground">
            <text className="timelineSelectsText" style={{ margin: '24px 24px 0px 24px' }}>
              {' '}
              Merge Date
            </text>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: mobile ? '0px' : '8px',
                margin: '-10px 24px 0px 24px'
              }}
              ref={refMonths}
            >
              <CustomDropdown
                open={isYearOpen}
                onClick={() => {
                  setIsYearOpen(!isYearOpen);
                  setIsMonthOpen(false);
                }}
              >
                <CustomDropdownToggle
                  style={{
                    maxWidth: mobile ? '51px' : '80px',
                    height: mobile ? '20px' : '30px',
                    borderRadius: '16px'
                  }}
                >
                  <text
                    className="timelineSelectsText"
                    style={{ position: 'relative', left: '5px', top: '1px' }}
                  >
                    {selectedYear ? `${selectedYear}` : 'Year'}
                  </text>
                  <img
                    className="arrowDropdown"
                    alt=""
                    style={{
                      position: 'absolute',
                      right: mobile ? '2px' : '9px',
                      top: mobile ? '2px' : '8px'
                    }}
                    src={arrowIcon}
                    height="12"
                    width="12"
                  />
                </CustomDropdownToggle>
                <CustomDropdownMenuTimeline>
                  <div
                    className="scroll_container"
                    style={{ pointerEvents: 'auto', height: '150px' }}
                  >
                    <div className="scroll_content_timeline">
                      <Scrollbars
                        renderTrackHorizontal={(props) => (
                          <div
                            {...props}
                            style={{ display: 'none' }}
                            className="track-horizontal"
                          />
                        )}
                        renderTrackVertical={(props) => (
                          <div {...props} className="track-vertical" />
                        )}
                        renderThumbVertical={(props) => (
                          <div {...props} className="thumb-vertical" />
                        )}
                        style={{ width: '100%' }}
                      >
                        {years(dataseta, selectedYear).map((d) => {
                          return (
                            <MenuItem
                              onClick={(event) => {
                                setSelectedYear(event.target.innerText);
                                if (
                                  months(dataseta, event.target.innerText).indexOf(
                                    selectedMonth
                                  ) === -1
                                ) {
                                  setSelectedMonth('');
                                }
                                setIsYearOpen(!isYearOpen);
                                event.preventDefault();
                                event.stopPropagation();
                              }}
                              style={{ fontWeight: '500' }}
                              eventKey={d}
                            >
                              <div
                                className="dropdownTextTimeline"
                                style={{
                                  borderRadius: '8px',
                                  background:
                                    selectedMonth === d ? 'rgba(211, 107, 40, 0.1)' : '#FFF'
                                }}
                              >
                                <text
                                  style={{
                                    color: '#8B8B8B',
                                    fontWeight: '500',
                                    position: 'relative',
                                    width: '80px',
                                    paddingLeft: '0px'
                                  }}
                                >
                                  {d}
                                </text>
                              </div>
                            </MenuItem>
                          );
                        })}
                      </Scrollbars>
                    </div>
                  </div>
                </CustomDropdownMenuTimeline>
              </CustomDropdown>
              <CustomDropdown
                open={isMonthOpen}
                onClick={() => {
                  setIsYearOpen(false);
                  setIsMonthOpen(!isMonthOpen);
                }}
              >
                <CustomDropdownToggle
                  style={{
                    maxWidth: mobile ? '51px' : '80px',
                    height: mobile ? '20px' : '30px',
                    borderRadius: '16px'
                  }}
                >
                  <text
                    className="timelineSelectsText"
                    style={{ position: 'relative', left: '5px', top: '1px' }}
                  >
                    {selectedMonth ? `${selectedMonth}` : ''}
                  </text>
                  <img
                    className="arrowDropdown"
                    alt=""
                    style={{
                      position: 'absolute',
                      right: mobile ? '2px' : '9px',
                      top: mobile ? '2px' : '8px'
                    }}
                    src={arrowIcon}
                    height={mobile ? '10' : '12'}
                    width={mobile ? '10' : '12'}
                  />
                </CustomDropdownToggle>
                <CustomDropdownMenuTimeline>
                  <div
                    className="scroll_container"
                    style={{ pointerEvents: 'auto', height: '150px' }}
                  >
                    <div className="scroll_content_timeline">
                      <Scrollbars
                        renderTrackHorizontal={(props) => (
                          <div
                            {...props}
                            style={{ display: 'none' }}
                            className="track-horizontal"
                          />
                        )}
                        renderTrackVertical={(props) => (
                          <div {...props} className="track-vertical" />
                        )}
                        renderThumbVertical={(props) => (
                          <div {...props} className="thumb-vertical" />
                        )}
                        style={{ width: '100%' }}
                      >
                        {months(dataseta, selectedYear).map((d) => {
                          return (
                            <MenuItem
                              onClick={(event) => {
                                setSelectedMonth(event.target.innerText);
                                setIsMonthOpen(false);
                                event.preventDefault();
                                event.stopPropagation();
                              }}
                              style={{ fontWeight: '500' }}
                              eventKey={d}
                            >
                              <div
                                className="dropdownTextTimeline"
                                style={{
                                  borderRadius: '8px',
                                  background:
                                    selectedMonth === d ? 'rgba(211, 107, 40, 0.1)' : '#FFF'
                                }}
                              >
                                <text
                                  style={{
                                    color: '#8B8B8B',
                                    fontWeight: '500',
                                    position: 'relative',
                                    width: '80px',
                                    paddingLeft: '0px'
                                  }}
                                >
                                  {d}
                                </text>
                              </div>
                            </MenuItem>
                          );
                        })}
                      </Scrollbars>
                    </div>
                  </div>
                </CustomDropdownMenuTimeline>
              </CustomDropdown>
            </div>
            <div id="App-header" className="App-header" style={{ marginTop: '0px' }}>
              <MemoizedVirtualMergeGraph
                economicClass={economicClass ? economicClass : 'Total residents assets'}
                economicCategory={economicCategory ? economicCategory : 'assets'}
                assets={dataseta}
                deposits={datasetd}
                liabilities={datasetl}
                loans={datasetlo}
                month={monthsArray.indexOf(selectedMonth) + 1}
                year={selectedYear}
                institutionOne={institutionOne}
                institutionTwo={institutionTwo}
              />
            </div>

            <text
              className="PerformanceText"
              style={{ margin: '-24px 24px 24px 24px', fontSize: mobile ? '10px' : '12px' }}
            >
              The prediction given is an estimation only. It is done by AI based on past performance
              only, and is not guaranteed to be accurate
            </text>
          </div>
        </div>
      )}
    </main>
  );
}

function VirtualMergeGraph({
  ADIs,
  economicClass,
  economicCategory,
  assets,
  deposits,
  liabilities,
  loans,
  month,
  year,
  institutionOne,
  institutionTwo
}) {
  return (
    <VirtualMerge
      ADIs={ADIs}
      economicClass={economicClass ? economicClass : 'Total residents assets'}
      economicCategory={economicCategory ? economicCategory : 'assets'}
      assets={assets}
      deposits={deposits}
      liabilities={liabilities}
      loans={loans}
      month={month}
      year={year}
      institutionOne={institutionOne}
      institutionTwo={institutionTwo}
    />
  );
}

const MemoizedVirtualMergeGraph = React.memo(VirtualMergeGraph);
