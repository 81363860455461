import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';
import { ThreeDots } from 'react-loader-spinner';
import { Auth } from 'aws-amplify';
import Dropdown, { MenuItem } from '@trendmicro/react-dropdown';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import Switch from '@mui/material/Switch';
// ----------------------------------------------------------------------
import {
  newKeysAssets,
  newKeysLiabilities,
  newKeysLoans,
  newKeysDeposits
} from '../utils/formatData';
import { compareCheck } from '../utils/formatNumber';
import BarChartRace from './views/BarChartRace';
import LineChart from './views/MultilineChart';
import '../design.css';
import { Slider } from '@mui/material';
import { useWindowSize } from 'src/windowSize';
import LZString from 'lz-string';

function names(data, filter) {
  if (filter) {
    data = data.filter((d) => d.type === filter);
  }
  const tempSet = new Set(data.map((d) => d.ADI));
  return [...tempSet];
}
function types(data) {
  return Object.keys(data[0])
    .filter((word) => word !== 'Period')
    .filter((word) => word !== 'ADI')
    .filter((word) => word !== 'type')
    .filter((word) => word !== 'partition')
    .filter((word) => word !== 'prediction')
    .filter((word) => word !== 'undefined')
    .filter((word) => !word.includes('upper'))
    .filter((word) => !word.includes('lower'));
}

const monthsArray = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

function months(data, year = 2019) {
  data = data.filter((d) => d.Period.indexOf(year) !== -1);
  const tempSet = new Set(
    data.map((d) => {
      const dateString = d.Period;
      return parseInt(
        dateString.substring(dateString.indexOf('-') + 1, dateString.lastIndexOf('-'))
      );
    })
  );
  const array = [...tempSet].sort((a, b) => a - b);
  const arrayMonthNames = new Array();
  array.forEach((d) => {
    arrayMonthNames.push(monthsArray[d - 1]);
  });
  return arrayMonthNames;
}

function years(data) {
  const tempSet = new Set(
    data.map((d) => {
      const dateString = d.Period;
      return parseInt(dateString);
    })
  );
  const array = [...tempSet].sort((a, b) => a - b);
  return array;
}

const changeKeyObjects = (arr, replaceKeys) =>
  arr.map((item) => {
    const newItem = {};
    Object.keys(item).forEach((key) => {
      newItem[replaceKeys[key]] = item[[key]];
    });
    return newItem;
  });
const LoadingIndicator = () => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div style={{ display: 'flex', height: '0px', justifyContent: 'center' }}>
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            top: '20vh'
          }}
        >
          <ThreeDots color="#cf7a43" height="100" width="100" />
        </div>
      </div>
    )
  );
};

const CustomDropdownMenu = styled(Dropdown.Menu)`
  padding-left: 16px;
  padding-top: 11px;
`;
CustomDropdownMenu.propTypes = Dropdown.Menu.propTypes;
CustomDropdownMenu.defaultProps = Dropdown.Menu.defaultProps;

const CustomDropdownMenuTimeline = styled(Dropdown.Menu)`
  width: 80px;
  padding-left: 8px;
  padding-top: 2px;
`;
CustomDropdownMenuTimeline.propTypes = Dropdown.Menu.propTypes;
CustomDropdownMenuTimeline.defaultProps = Dropdown.Menu.defaultProps;

const CustomSlider = styled(Slider)({
  color: '#FFFFFF'
});

const CustomSliderMobile = styled(Slider)({
  color: '#FFFFFF',
  padding: '0 0 0 0',
  marginBottom: '8px',
  '& .MuiSlider-thumb': {
    width: '10px !important',
    height: '10px !important'
  },
  '& .MuiSlider-rail': {
    height: '2px'
  }
});

const CustomSwitch = styled(Switch)({
  width: 320,
  maxWidth: 320,
  height: 60,
  borderRadius: '100px',
  padding: 0,
  '& .MuiSwitch-switchBase': {
    margin: 2,
    padding: 0,
    transform: 'translateX(0px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(160px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('/static/icons/graph-type-line.svg')`
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#F0F0F0'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#FFF',
    width: 156,
    height: 56,
    boxShadow: 'none',
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url('/static/icons/graph-type-bar.svg')`,
      backgroundPosition: 'center'
    }
  },
  '& .MuiSwitch-track': {
    height: 60,
    opacity: 1,
    backgroundColor: '#F0F0F0',
    backgroundPosition: 'center',
    backgroundImage: `url('/static/icons/graph-type-background.svg')`,
    borderRadius: 100
  }
});

const CustomSwitchMobile = styled(Switch)({
  width: 64,
  maxWidth: 64,
  height: 36,
  borderRadius: '50px',
  padding: 0,
  '& .MuiSwitch-switchBase': {
    margin: 2,
    padding: 0,
    transform: 'translate(0px, 0px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translate(29px, 0px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('/static/icons/graph-type-line-mobile.svg')`
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#F0F0F0'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#FFF',
    width: 32,
    height: 32,
    boxShadow: 'none',
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url('/static/icons/graph-type-bar-mobile.svg')`,
      backgroundPosition: 'center'
    }
  },
  '& .MuiSwitch-track': {
    height: 36,
    opacity: 1,
    backgroundColor: '#F0F0F0',
    backgroundPosition: 'center',
    backgroundImage: `url('/static/icons/graph-type-background-mobile.svg')`,
    borderRadius: 100
  }
});

const CustomDropdown = styled(Dropdown)``;
CustomDropdown.propTypes = Dropdown.propTypes;
CustomDropdown.defaultProps = Dropdown.defaultProps;

const CustomDropdownToggle = styled(Dropdown.Toggle)`
  border-color: white;
  background-color: white;
`;
CustomDropdownToggle.propTypes = Dropdown.Toggle.propTypes;
CustomDropdownToggle.defaultProps = Dropdown.Toggle.defaultProps;

export default function MultilineD3() {
  const [dataseta, setDataseta] = useState(null);
  const [datasetlo, setDatasetlo] = useState(null);
  const [datasetd, setDatasetd] = useState(null);
  const [datasetl, setDatasetl] = useState(null);
  const [datasetal, setDatasetal] = useState(null);
  const [datasetlol, setDatasetlol] = useState(null);
  const [datasetdl, setDatasetdl] = useState(null);
  const [datasetll, setDatasetll] = useState(null);
  const [ADIList, setADIList] = useState({});

  // Clear localstorage
  // localStorage.clear();
  // Get cached data
  let assetsCached = [];
  let assetsM = JSON.parse(localStorage.getItem('assets-m'));
  try {
    assetsCached = Promise.resolve(JSON.parse(LZString.decompress(localStorage.getItem('assets'))));
  } catch (error) {
    assetsM = false;
  }
  const assetsLastMod = '1'; // assetsM ? assetsM : '1';

  let loansCached = [];
  let loansM = JSON.parse(localStorage.getItem('loans-m'));
  try {
    loansCached = Promise.resolve(JSON.parse(LZString.decompress(localStorage.getItem('loans'))));
  } catch (error) {
    loansM = false;
  }
  const loansLastMod = loansM ? loansM : '1';

  let depositsCached = [];
  let depositsM = JSON.parse(localStorage.getItem('deposits-m'));
  try {
    depositsCached = Promise.resolve(
      JSON.parse(LZString.decompress(localStorage.getItem('deposits')))
    );
  } catch (error) {
    depositsM = false;
  }
  const depositsLastMod = depositsM ? depositsM : '1';

  let liabilitiesCached = [];
  let liabilitiesM = JSON.parse(localStorage.getItem('liabilities-m'));
  try {
    liabilitiesCached = Promise.resolve(
      JSON.parse(LZString.decompress(localStorage.getItem('liabilities')))
    );
  } catch (error) {
    liabilitiesM = false;
  }
  const liabilitiesLastMod = liabilitiesM ? liabilitiesM : '1';

  useEffect(() => {
    // Get cognito userpool token
    // Disable requirement for auth
    // Auth.currentSession().then((data) => {
    trackPromise(
      Promise.all([
        fetch(
          'https://0b18hurmgf.execute-api.ap-southeast-2.amazonaws.com/dev/items?heading=assets&modified=' +
            assetsLastMod,
          {
            /*headers: {
                Authorization: `Bearer ${data.idToken.jwtToken}`
              }*/
          }
        ).then((response) => {
          let data = '';
          let lastMod = '';
          if (response.status === 200) {
            data = response.json();
            lastMod = response.headers.get('last-modified');
          } else {
            data = assetsCached;
            lastMod = assetsLastMod;
          }
          return { data, lastMod };
        }),
        fetch(
          'https://0b18hurmgf.execute-api.ap-southeast-2.amazonaws.com/dev/items?heading=loans&modified=' +
            loansLastMod,
          {
            /*headers: {
              Authorization: `Bearer ${data.idToken.jwtToken}`
            }*/
          }
        ).then((response) => {
          let data = '';
          let lastMod = '';
          if (response.status === 200) {
            data = response.json();
            lastMod = response.headers.get('last-modified');
          } else {
            data = loansCached;
            lastMod = loansLastMod;
          }
          return { data, lastMod };
        }),
        fetch(
          'https://0b18hurmgf.execute-api.ap-southeast-2.amazonaws.com/dev/items?heading=deposits&modified=' +
            depositsLastMod,
          {
            /*headers: {
              Authorization: `Bearer ${data.idToken.jwtToken}`
            }*/
          }
        ).then((response) => {
          let data = '';
          let lastMod = '';
          if (response.status === 200) {
            data = response.json();
            lastMod = response.headers.get('last-modified');
          } else {
            data = depositsCached;
            lastMod = depositsLastMod;
          }
          return { data, lastMod };
        }),
        fetch(
          'https://0b18hurmgf.execute-api.ap-southeast-2.amazonaws.com/dev/items?heading=liabilities&modified=' +
            liabilitiesLastMod,
          {
            /*headers: {
              Authorization: `Bearer ${data.idToken.jwtToken}`
            }*/
          }
        ).then((response) => {
          let data = '';
          let lastMod = '';
          if (response.status === 200) {
            data = response.json();
            lastMod = response.headers.get('last-modified');
          } else {
            data = liabilitiesCached;
            lastMod = liabilitiesLastMod;
          }
          return { data, lastMod };
        })
      ]).then(([assets, loans, deposits, liabilities]) => {
        assets.data.then((data) => {
          const lastModified = assets.lastMod;
          if (compareCheck(lastModified, assetsLastMod)) {
            const renamedData = changeKeyObjects(data, newKeysAssets).sort((a, b) =>
              a.ADI > b.ADI ? 1 : b.ADI > a.ADI ? -1 : 0
            );
            setADIList(names(renamedData));
            setDataseta(renamedData.filter((d) => d.prediction === false));
            setDatasetal(renamedData);
            localStorage.removeItem('assets');
            localStorage.removeItem('assets-m');
            localStorage.setItem('assets', LZString.compress(JSON.stringify({ data })));
            localStorage.setItem('assets-m', JSON.stringify(assets.lastMod));
          } else {
            data = Object.values(data);
            const renamedData = changeKeyObjects(data[0], newKeysAssets).sort((a, b) =>
              a.ADI > b.ADI ? 1 : b.ADI > a.ADI ? -1 : 0
            );
            setADIList(names(renamedData));
            setDataseta(renamedData.filter((d) => d.prediction === false));
            setDatasetal(renamedData);
          }
        });

        loans.data.then((data) => {
          const lastModified = loans.lastMod;
          if (compareCheck(lastModified, loansLastMod)) {
            const renamedData = changeKeyObjects(data, newKeysLoans);
            setDatasetlo(renamedData.filter((d) => d.prediction === false));
            setDatasetlol(renamedData);
            localStorage.removeItem('loans');
            localStorage.removeItem('loans-m');
            localStorage.setItem('loans', LZString.compress(JSON.stringify({ data })));
            localStorage.setItem('loans-m', JSON.stringify(loans.lastMod));
          } else {
            data = Object.values(data);
            const renamedData = changeKeyObjects(data[0], newKeysLoans);
            setDatasetlo(renamedData.filter((d) => d.prediction === false));
            setDatasetlol(renamedData);
          }
        });

        deposits.data.then((data) => {
          const lastModified = deposits.lastMod;
          if (compareCheck(lastModified, depositsLastMod)) {
            const renamedData = changeKeyObjects(data, newKeysDeposits);
            setDatasetd(renamedData.filter((d) => d.prediction === false));
            setDatasetdl(renamedData);
            localStorage.removeItem('deposits');
            localStorage.removeItem('deposits-m');
            localStorage.setItem('deposits', LZString.compress(JSON.stringify({ data })));
            localStorage.setItem('deposits-m', JSON.stringify(deposits.lastMod));
          } else {
            data = Object.values(data);
            const renamedData = changeKeyObjects(data[0], newKeysDeposits);
            setDatasetd(renamedData.filter((d) => d.prediction === false));
            setDatasetdl(renamedData);
          }
        });
        liabilities.data.then((data) => {
          const lastModified = liabilities.lastMod;
          if (compareCheck(lastModified, liabilitiesLastMod)) {
            const renamedData = changeKeyObjects(data, newKeysLiabilities);
            setDatasetl(renamedData.filter((d) => d.prediction === false));
            setDatasetll(renamedData);
            localStorage.removeItem('liabilities');
            localStorage.removeItem('liabilities-m');
            localStorage.setItem('liabilities', LZString.compress(JSON.stringify({ data })));
            localStorage.setItem('liabilities-m', JSON.stringify(liabilities.lastMod));
          } else {
            data = Object.values(data);
            const renamedData = changeKeyObjects(data[0], newKeysLiabilities);
            setDatasetl(renamedData.filter((d) => d.prediction === false));
            setDatasetll(renamedData);
          }
        });
      })
    );
  }, []);
  //}, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  //Economic Class
  const [isEconomicClassOpen, setIsEconomicClassOpen] = useState(false);
  const [economicClassIcon, setEconomicClassIcon] = useState('/static/icons/hand-coin-outline.svg');
  const [economicClassFontColor, setEconomicClassFontColor] = useState('#5E5F5F');
  const [economicCategory, setEconomicCategory] = useState('');
  const [economicClass, setEconomicClass] = useState('');
  const [isAssetsOpen, setIsAssetsOpen] = useState(false);
  const [isDepositsOpen, setIsDepositsOpen] = useState(false);
  const [isLiabilitiesOpen, setIsLiabilitiesOpen] = useState(false);
  const [isLoansOpen, setIsLoansOpen] = useState(false);

  //Timeline states
  const [isMonthOpen, setIsMonthOpen] = useState(false);
  const [isYearOpen, setIsYearOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState('Mar');
  const [monthTrigger, setMonthTrigger] = useState(false);
  const [selectedYear, setSelectedYear] = useState(2019);
  const [yearTrigger, setYearTrigger] = useState(false);
  const [sliderValue, setSliderValue] = useState(3);

  //Search bar query
  const [query, setQuery] = useState('');
  // open state for drop down boxes
  // Main menu, Top 10, Big 4, Mutual, All
  const [isInstitutionOpen, setIsInstitutionOpen] = useState(false);
  const [isBig4Open, setIsBig4Open] = useState(false);
  const [isMutualOpen, setIsMutualOpen] = useState(false);
  const [isAllOpen, setIsAllOpen] = useState(false);

  //number of selected check boxes
  const [playButtonState, setPlayButtonState] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [fontColor, setFontColor] = useState('#5E5F5F');
  const [institutionIcon, setInstitutionIcon] = useState('/static/icons/bank-outline.svg');
  const [arrowIcon, setArrowIcon] = useState('/static/icons/chevron.svg');
  const [arrowIconOrange, setarrowIconOrange] = useState('/static/icons/chevron-orange.svg');
  const [currentCountWarning, setCurrentCountWarning] = useState(false);
  const [checkedState, setCheckedState] = useState(new Array(150).fill(false));
  function handleResetFilters() {
    //Economic Class
    setPlayButtonState(false);
    setIsEconomicClassOpen(false);
    setEconomicClassIcon('/static/icons/hand-coin-outline.svg');
    setEconomicClassFontColor('#5E5F5F');
    setEconomicClass('');
    setEconomicCategory('');
    setIsAssetsOpen(false);
    setIsDepositsOpen(false);
    setIsLiabilitiesOpen(false);
    setIsLoansOpen(false);

    //Search bar query
    setQuery('');
    // open state for drop down boxes
    // Main menu, Top 10, Big 4, Mutual, All
    setIsInstitutionOpen(false);
    setIsBig4Open(false);
    setIsMutualOpen(false);
    setIsAllOpen(false);

    //number of selected check boxes
    setSelectedCount(0);
    setFontColor('#5E5F5F');
    setInstitutionIcon('/static/icons/bank-outline.svg');
    setArrowIcon('/static/icons/chevron.svg');
    setarrowIconOrange('/static/icons/chevron-orange.svg');
    setCurrentCountWarning(false);
    setCheckedState(new Array(150).fill(false));
  }

  const [selectedADI, setSelectedADI] = useState('');

  function handleApply() {
    let el = ReactDOM.findDOMNode(dropdownMenu);
    const checkedBoxes = Array.from(el.querySelectorAll('[type="checkbox"]:checked'));
    const checkedADIs = checkedBoxes.map((d) => d.attributes.name.value);
    if (checkedBoxes.length == 0) {
      handleTop10();
    } else {
      setSelectedADI(checkedADIs);
      setIsTop10(false);
      setFontColor('#D36B28');
      setInstitutionIcon('/static/icons/bank-outline-orange.svg');
    }
  }

  function handleEconomicClassChange(event, category) {
    setEconomicClass(event.target.innerText);
    setEconomicCategory(category);
    setEconomicClassIcon('/static/icons/hand-coin-outline-orange.svg');
    setEconomicClassFontColor('#D36B28');
    if (selectedADI) {
      handleApply(event);
    }
  }

  const [isLineChart, setIsLineChart] = useState(true);

  function handleSwitchChange() {
    setIsLineChart(!isLineChart);
  }

  let dropdownMenu = null;
  let yearText = null;
  let monthText = null;
  function handleChange(event) {
    // Check if click was on dropdowntext or the div
    if (event.target.className === 'dropdownText') {
      const index = parseInt(event.target.firstChild.attributes.index.value);
      handleSelectBox(index, true, checkedState[index]);
    } else if (event.target.className === 'ADIText') {
      const index = parseInt(event.target.attributes.index.value);
      handleSelectBox(index, true, checkedState[index]);
    }
    if (!isTop10) {
      setFontColor('#5E5F5F');
      setInstitutionIcon('/static/icons/bank-outline.svg');
    }
  }

  const [isTop10, setIsTop10] = useState(false);

  function handleTop10(type = 'Mutual') {
    const eClass = economicClass ? economicClass : 'Total residents assets';
    let dataset = dataseta;
    if (economicCategory === 'loans') {
      dataset = datasetlo;
    } else if (economicCategory === 'liabilities') {
      dataset = datasetl;
    } else if (economicCategory === 'deposits') {
      dataset = datasetd;
    }
    //const Period = new Date(selectedYear, monthsArray.indexOf(selectedMonth)+1, 0);
    //const PeriodUTC = new Date(Date.UTC(Period.getFullYear(), Period.getMonth(), Period.getDate())).toISOString().substring(0, 10);
    const maxDate = dataset.reduce((prev, current) =>
      prev.Period > current.Period ? prev : current
    ).Period;
    let filteredData = dataset.filter((d) => d.Period === maxDate);
    if (type !== 'All') {
      filteredData = filteredData.filter((d) => d.type === type);
    }
    filteredData = filteredData
      .sort((a, b) => {
        return b[eClass] - a[eClass];
      })
      .slice(0, 10);
    const top10 = filteredData.map((a) => a.ADI);
    setIsTop10(true);
    setSelectedADI(top10);
    setSelectedCount(0);
    setCheckedState(new Array(150).fill(false));
    setFontColor('#D36B28');
    setInstitutionIcon('/static/icons/bank-outline-orange.svg');
  }

  function handleOnMouseEnter(
    institutions = true,
    first = false,
    second = false,
    third = false,
    fourth = false
  ) {
    if (!mobile) {
      if (institutions) {
        setIsBig4Open(first);
        setIsMutualOpen(second);
        setIsAllOpen(third);
      } else {
        setIsAssetsOpen(first);
        setIsDepositsOpen(second);
        setIsLiabilitiesOpen(third);
        setIsLoansOpen(fourth);
      }
    }
  }

  function handleSelectBox(position, externalTrigger, currentState) {
    let el = ReactDOM.findDOMNode(dropdownMenu);
    let currentCount = el.querySelectorAll('[type="checkbox"]:checked').length;
    if (externalTrigger && !currentState) {
      currentCount++;
    } else if (externalTrigger && currentState) {
      currentCount--;
    }
    if (currentCount > 10) {
      setCurrentCountWarning(true);
      return null;
    }

    let updatedCheckedState = {};
    if (checkedState.length) {
      updatedCheckedState = checkedState.map((item, index) => (index === position ? !item : item));
      setCheckedState(updatedCheckedState);
    }
    setSelectedCount(currentCount);
  }

  const window = useWindowSize();
  const [mobile, setMobile] = useState(window);
  useEffect(() => {
    setMobile(window);
  }, [window]);
  const ref = useRef();
  const refMonths = useRef();
  const refInstitution = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isEconomicClassOpen && ref.current && !ref.current.contains(e.target)) {
        setIsEconomicClassOpen(false);
      }
      if (isYearOpen && refMonths.current && !refMonths.current.contains(e.target)) {
        setIsYearOpen(false);
      }
      if (isMonthOpen && refMonths.current && !refMonths.current.contains(e.target)) {
        setIsMonthOpen(false);
      }
      if (
        isInstitutionOpen &&
        refInstitution.current &&
        !refInstitution.current.contains(e.target)
      ) {
        setIsInstitutionOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isEconomicClassOpen, isInstitutionOpen, isYearOpen, isMonthOpen]);

  return (
    <main id="main" className="main">
      <div className="Frame65">
        <text className="Performance">Performance</text>
        <text className="PerformanceText">
          Choose one or more institutions and the desired economic class, to view and compare their
          financial performance.
        </text>
      </div>
      {mobile && <hr className="LineMobile" />}
      <div id="tooltip" />
      <div id="missingData" />
      <LoadingIndicator />
      {dataseta &&
        datasetd &&
        datasetl &&
        datasetlo &&
        datasetal &&
        datasetdl &&
        datasetll &&
        datasetlol && (
          <div className="App">
            <div className="Frame12">
              <div className="Frame11">
                <text className="Institution">Institution</text>
                <div className="Frame1_2" ref={refInstitution}>
                  <CustomDropdown
                    tabIndex="0"
                    id="InstitutionDropdown"
                    open={isInstitutionOpen}
                    onClick={() => {
                      setIsInstitutionOpen(!isInstitutionOpen);
                      setIsBig4Open(false);
                      setIsAllOpen(false);
                      setIsMutualOpen(false);
                      setIsEconomicClassOpen(false);
                    }}
                    onSelect={(eventKey, event) => {
                      handleChange(event);
                    }}
                  >
                    <CustomDropdownToggle noCaret title={`Institution (${selectedCount})`}>
                      <img
                        className="bankOutline"
                        alt=""
                        src={institutionIcon}
                        height="24"
                        width="24"
                      />
                      <text
                        style={{ position: 'relative', left: '45px', top: '1px', color: fontColor }}
                      >
                        {selectedCount
                          ? `Institution (${selectedCount})`
                          : isTop10
                          ? 'Top 10'
                          : 'Institution'}
                      </text>
                      <img
                        className="arrowDropdown"
                        alt=""
                        src={fontColor === '#D36B28' ? arrowIconOrange : arrowIcon}
                        height="24"
                        width="24"
                      />
                    </CustomDropdownToggle>
                    <CustomDropdownMenu
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      {/*<MenuItem onClick={(event) => {
                                  handleTop10();
                                  setIsInstitutionOpen(false);
                                  setIsBig4Open(false);
                                  setIsAllOpen(false);
                                  setIsMutualOpen(false);
                                  event.preventDefault();
                                  event.stopPropagation()}
                                  }
                                  style={{ paddingLeft: "0px"}} eventKey="Top 10"
                                >
                                <div className="dropdownHeading"  style={{ display: "flex", flexDirection: "row", alignItems: "center", height: "32px"}}>
                                  <text style={{ fontWeight: "600", position: "relative", width: "130px", paddingLeft: "8px"}}>Top 10</text>
                                </div>
                                </MenuItem>*/}
                      <MenuItem
                        onClick={(event) => {
                          setIsBig4Open(!isBig4Open);
                          setIsAllOpen(false);
                          setIsMutualOpen(false);
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                        style={{ cursor: 'default', paddingLeft: '0px' }}
                        eventKey="Big 4"
                      >
                        <div
                          className="dropdownHeading"
                          onMouseEnter={() => {
                            handleOnMouseEnter(true, true, false, false, false);
                          }}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            height: '32px'
                          }}
                        >
                          <text
                            style={{
                              color: isBig4Open ? '#D36B28' : '#5E5F5F',
                              fontWeight: '600',
                              position: 'relative',
                              width: '130px',
                              paddingLeft: '8px'
                            }}
                          >
                            Big 4
                          </text>
                          <img
                            className="arrowSubMenu"
                            alt=""
                            src={isBig4Open ? arrowIconOrange : arrowIcon}
                            height="24"
                            width="24"
                          />
                        </div>
                        <Dropdown.SubMenu
                          open={isBig4Open}
                          style={{ pointerEvents: 'none', cursor: 'default' }}
                        >
                          <div
                            style={{ pointerEvents: 'auto', cursor: 'default' }}
                            onClick={(event) => {
                              handleChange(event);
                              event.preventDefault();
                              event.stopPropagation();
                            }}
                          >
                            {mobile && (
                              <div className="mobileHeadingMenu">
                                Big 4
                                <button
                                  id="return"
                                  className="mobileButton"
                                  onClick={() => {
                                    setIsBig4Open(false);
                                  }}
                                >
                                  <img src="/static/icons/arrow-left.svg" />
                                </button>
                              </div>
                            )}
                            <div className="scroll_container" style={{ height: '175px' }}>
                              <div className="scroll_content">
                                <Scrollbars
                                  renderTrackHorizontal={(props) => (
                                    <div
                                      {...props}
                                      style={{ display: 'none' }}
                                      className="track-horizontal"
                                    />
                                  )}
                                  renderTrackVertical={(props) => (
                                    <div {...props} className="track-vertical" />
                                  )}
                                  renderThumbVertical={(props) => (
                                    <div {...props} className="thumb-vertical" />
                                  )}
                                  style={{ width: '100%' }}
                                >
                                  {names(dataseta, 'Big4').map((d) => {
                                    const index = ADIList.indexOf(d);
                                    return (
                                      <MenuItem
                                        style={{ pointerEvents: 'auto', fontWeight: '500' }}
                                        eventKey={d}
                                      >
                                        <div
                                          className="dropdownText"
                                          style={{
                                            borderRadius:
                                              checkedState[index] &&
                                              checkedState[index + 1] &&
                                              checkedState[index - 1]
                                                ? '0px 0px 0px 0px'
                                                : checkedState[index] && checkedState[index - 1]
                                                ? '0px 0px 8px 8px'
                                                : checkedState[index] && checkedState[index + 1]
                                                ? '8px 8px 0px 0px'
                                                : '8px',
                                            background: checkedState[index]
                                              ? 'rgba(211, 107, 40, 0.1)'
                                              : '#FFF'
                                          }}
                                        >
                                          <input
                                            key={d + checkedState[index]}
                                            type="checkbox"
                                            name={`menuitem-${d}`}
                                            index={index}
                                            className="checkbox-neo"
                                            style={{ marginRight: 5 }}
                                            checked={checkedState[index]}
                                            onChange={() => {
                                              handleSelectBox(true, checkedState[index]);
                                            }}
                                          />
                                          <text
                                            className="ADIText"
                                            index={index}
                                            style={{
                                              color: checkedState[index] ? '#D36B28' : '#5E5F5F',
                                              fontWeight: '500'
                                            }}
                                          >
                                            {d}
                                          </text>
                                        </div>
                                      </MenuItem>
                                    );
                                  })}
                                </Scrollbars>
                              </div>
                            </div>
                            <div className="dropdownBase">
                              {currentCountWarning && <text>Select up to 10 items</text>}
                              <div className="cancelApply">
                                <button
                                  className="imageButton"
                                  onClick={
                                    //Close all windows on click
                                    (event) => {
                                      setIsInstitutionOpen(false);
                                      event.preventDefault();
                                      event.stopPropagation();
                                    }
                                  }
                                >
                                  <img src="/static/icons/button-cancel.svg" />
                                </button>
                                <button
                                  className="imageButton"
                                  onClick={
                                    //Close all windows on click
                                    (event) => {
                                      setIsInstitutionOpen(false);
                                      setQuery('');
                                      handleApply(event);
                                      event.preventDefault();
                                      event.stopPropagation();
                                    }
                                  }
                                >
                                  <img src="/static/icons/button-apply.svg" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </Dropdown.SubMenu>
                      </MenuItem>
                      <MenuItem
                        onClick={(event) => {
                          setIsBig4Open(false);
                          setIsAllOpen(false);
                          setIsMutualOpen(!isMutualOpen);
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                        style={{ paddingLeft: '0px' }}
                        eventKey="Mutual"
                      >
                        <div
                          className="dropdownHeading"
                          onMouseEnter={() => {
                            handleOnMouseEnter(true, false, true, false, false);
                          }}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            height: '32px'
                          }}
                        >
                          <text
                            style={{
                              color: isMutualOpen ? '#D36B28' : '#5E5F5F',
                              fontWeight: '600',
                              position: 'relative',
                              width: '130px',
                              paddingLeft: '8px'
                            }}
                          >
                            Mutual
                          </text>
                          <img
                            className="arrowSubMenu"
                            alt=""
                            src={isMutualOpen ? arrowIconOrange : arrowIcon}
                            height="24"
                            width="24"
                          />
                        </div>
                        <Dropdown.SubMenu
                          open={isMutualOpen}
                          style={{ pointerEvents: 'none', cursor: 'default' }}
                        >
                          <div
                            style={{ pointerEvents: 'auto', cursor: 'default' }}
                            onClick={(event) => {
                              handleChange(event);
                              event.preventDefault();
                              event.stopPropagation();
                            }}
                          >
                            {mobile && (
                              <div className="mobileHeadingMenu">
                                Mutual
                                <button
                                  id="return"
                                  className="mobileButton"
                                  onClick={() => {
                                    setIsMutualOpen(false);
                                  }}
                                >
                                  <img src="/static/icons/arrow-left.svg" />
                                </button>
                              </div>
                            )}
                            <div className="searchBar">
                              <input
                                id="InstitutionFilter"
                                type="text"
                                placeholder="Search..."
                                className="labelInstitutionFilter"
                                onChange={(event) => {
                                  setQuery(event.target.value);
                                }}
                                value={query ? query : ''}
                              ></input>
                            </div>
                            <div className="scroll_container">
                              <div className="scroll_content">
                                <Scrollbars
                                  renderTrackHorizontal={(props) => (
                                    <div
                                      {...props}
                                      style={{ display: 'none' }}
                                      className="track-horizontal"
                                    />
                                  )}
                                  renderTrackVertical={(props) => (
                                    <div {...props} className="track-vertical" />
                                  )}
                                  renderThumbVertical={(props) => (
                                    <div {...props} className="thumb-vertical" />
                                  )}
                                  style={{ width: '100%' }}
                                >
                                  <MenuItem
                                    style={{ pointerEvents: 'auto', fontWeight: '500' }}
                                    eventKey={'Top 10'}
                                    onClick={(event) => {
                                      handleTop10('Mutual'), event.stopPropagation();
                                    }}
                                  >
                                    <div className="dropdownText">
                                      <text
                                        className="ADIText"
                                        index={-1}
                                        style={{
                                          marginLeft: '28px',
                                          textAlign: 'center',
                                          color: isTop10 ? '#D36B28' : '#5E5F5F',
                                          fontWeight: '600'
                                        }}
                                      >
                                        Top 10
                                      </text>
                                    </div>
                                  </MenuItem>
                                  {names(dataseta, 'Mutual')
                                    .filter((adi) => {
                                      if (query === '') {
                                        return adi;
                                      } else if (adi.toLowerCase().includes(query.toLowerCase())) {
                                        return adi;
                                      }
                                    })
                                    .map((d) => {
                                      const index = ADIList.indexOf(d);
                                      return (
                                        <MenuItem style={{ fontWeight: '500' }} eventKey={d}>
                                          <div
                                            className="dropdownText"
                                            style={{
                                              borderRadius:
                                                checkedState[index] &&
                                                checkedState[index + 1] &&
                                                checkedState[index - 1]
                                                  ? '0px 0px 0px 0px'
                                                  : checkedState[index] && checkedState[index - 1]
                                                  ? '0px 0px 8px 8px'
                                                  : checkedState[index] && checkedState[index + 1]
                                                  ? '8px 8px 0px 0px'
                                                  : '8px',
                                              background: checkedState[index]
                                                ? 'rgba(211, 107, 40, 0.1)'
                                                : '#FFF'
                                            }}
                                          >
                                            <input
                                              key={d + checkedState[index]}
                                              type="checkbox"
                                              name={`menuitem-${d}`}
                                              index={index}
                                              className="checkbox-neo"
                                              style={{ marginRight: 5 }}
                                              checked={checkedState[index]}
                                              onChange={(event) => {
                                                handleSelectBox(index, true, checkedState[index]);
                                              }}
                                            />
                                            <text
                                              className="ADIText"
                                              index={index}
                                              style={{
                                                color: checkedState[index] ? '#D36B28' : '#5E5F5F',
                                                fontWeight: '500'
                                              }}
                                            >
                                              {d}
                                            </text>
                                          </div>
                                        </MenuItem>
                                      );
                                    })}
                                </Scrollbars>
                              </div>
                            </div>
                            <div className="dropdownBase">
                              {currentCountWarning && <text>Select up to 10 items</text>}
                              <div className="cancelApply">
                                <button
                                  className="imageButton"
                                  onClick={
                                    //Close all windows on click
                                    (event) => {
                                      setIsInstitutionOpen(false);
                                      event.preventDefault();
                                      event.stopPropagation();
                                    }
                                  }
                                >
                                  <img src="/static/icons/button-cancel.svg" />
                                </button>
                                <button
                                  className="imageButton"
                                  onClick={
                                    //Close all windows on click
                                    (event) => {
                                      setIsInstitutionOpen(false);
                                      setQuery('');
                                      handleApply(event);
                                      event.preventDefault();
                                      event.stopPropagation();
                                    }
                                  }
                                >
                                  <img src="/static/icons/button-apply.svg" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </Dropdown.SubMenu>
                      </MenuItem>
                      <MenuItem
                        onClick={(event) => {
                          setIsBig4Open(false);
                          setIsAllOpen(!isAllOpen);
                          setIsMutualOpen(false);
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                      >
                        <div
                          className="dropdownHeading"
                          onMouseEnter={() => {
                            handleOnMouseEnter(true, false, false, true, false);
                          }}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            height: '32px'
                          }}
                        >
                          <text
                            style={{
                              color: isAllOpen ? '#D36B28' : '#5E5F5F',
                              fontWeight: '600',
                              position: 'relative',
                              width: '130px',
                              paddingLeft: '8px'
                            }}
                          >
                            All
                          </text>
                          <img
                            className="arrowSubMenu"
                            alt=""
                            src={isAllOpen ? arrowIconOrange : arrowIcon}
                            height="24"
                            width="24"
                          />
                        </div>
                        <Dropdown.SubMenu
                          open={isAllOpen}
                          style={{ pointerEvents: 'none', cursor: 'default' }}
                        >
                          <div
                            style={{ pointerEvents: 'auto', cursor: 'default' }}
                            onClick={(event) => {
                              handleChange(event);
                              event.preventDefault();
                              event.stopPropagation();
                            }}
                          >
                            {mobile && (
                              <div className="mobileHeadingMenu">
                                All
                                <button
                                  id="return"
                                  className="mobileButton"
                                  onClick={() => {
                                    setIsAllOpen(false);
                                  }}
                                >
                                  <img src="/static/icons/arrow-left.svg" />
                                </button>
                              </div>
                            )}
                            <div className="searchBar">
                              <input
                                id="InstitutionFilter"
                                type="text"
                                placeholder="Search..."
                                className="labelInstitutionFilter"
                                onChange={(event) => setQuery(event.target.value)}
                                value={query ? query : ''}
                              ></input>
                            </div>
                            <div className="scroll_container">
                              <div className="scroll_content">
                                <Scrollbars
                                  renderTrackHorizontal={(props) => (
                                    <div
                                      {...props}
                                      style={{ display: 'none' }}
                                      className="track-horizontal"
                                    />
                                  )}
                                  renderTrackVertical={(props) => (
                                    <div {...props} className="track-vertical" />
                                  )}
                                  renderThumbVertical={(props) => (
                                    <div {...props} className="thumb-vertical" />
                                  )}
                                  style={{ width: '100%' }}
                                >
                                  <MenuItem
                                    style={{ pointerEvents: 'auto', fontWeight: '500' }}
                                    eventKey={'Top 10'}
                                    onClick={(event) => {
                                      handleTop10('All'), event.stopPropagation();
                                    }}
                                  >
                                    <div className="dropdownText">
                                      <text
                                        className="ADIText"
                                        index={-1}
                                        style={{
                                          marginLeft: '28px',
                                          textAlign: 'center',
                                          color: isTop10 ? '#D36B28' : '#5E5F5F',
                                          fontWeight: '600'
                                        }}
                                      >
                                        Top 10
                                      </text>
                                    </div>
                                  </MenuItem>
                                  {names(dataseta)
                                    .filter((adi) => {
                                      if (query === '') {
                                        return adi;
                                      } else if (adi.toLowerCase().includes(query.toLowerCase())) {
                                        return adi;
                                      }
                                    })
                                    .map((d) => {
                                      const index = ADIList.indexOf(d);
                                      return (
                                        <MenuItem style={{ fontWeight: '500' }} eventKey={d}>
                                          <div
                                            className="dropdownText"
                                            style={{
                                              borderRadius:
                                                checkedState[index] &&
                                                checkedState[index + 1] &&
                                                checkedState[index - 1]
                                                  ? '0px 0px 0px 0px'
                                                  : checkedState[index] && checkedState[index - 1]
                                                  ? '0px 0px 8px 8px'
                                                  : checkedState[index] && checkedState[index + 1]
                                                  ? '8px 8px 0px 0px'
                                                  : '8px',
                                              background: checkedState[index]
                                                ? 'rgba(211, 107, 40, 0.1)'
                                                : '#FFF'
                                            }}
                                          >
                                            <input
                                              key={d + checkedState[index]}
                                              type="checkbox"
                                              name={`${d}`}
                                              index={index}
                                              className="checkbox-neo"
                                              style={{ marginRight: 5 }}
                                              checked={checkedState[index]}
                                              onChange={(event) => {
                                                handleSelectBox(index);
                                              }}
                                            />
                                            <text
                                              className="ADIText"
                                              index={index}
                                              style={{
                                                color: checkedState[index] ? '#D36B28' : '#5E5F5F',
                                                fontWeight: '500'
                                              }}
                                            >
                                              {d}
                                            </text>
                                          </div>
                                        </MenuItem>
                                      );
                                    })}
                                </Scrollbars>
                              </div>
                            </div>
                            <div className="dropdownBase">
                              {currentCountWarning && <text>Select up to 10 items</text>}
                              <div className="cancelApply">
                                <button
                                  className="imageButton"
                                  onClick={
                                    //Close all windows on click
                                    (event) => {
                                      setIsInstitutionOpen(false);
                                      event.preventDefault();
                                      event.stopPropagation();
                                    }
                                  }
                                >
                                  <img src="/static/icons/button-cancel.svg" />
                                </button>
                                <button
                                  className="imageButton"
                                  onClick={
                                    //Close all windows on click
                                    (event) => {
                                      setIsInstitutionOpen(false);
                                      setQuery('');
                                      handleApply(event);
                                      event.preventDefault();
                                      event.stopPropagation();
                                    }
                                  }
                                >
                                  <img src="/static/icons/button-apply.svg" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </Dropdown.SubMenu>
                        {/* Dropdown for  handling search (invisible)*/}
                        <Dropdown.SubMenu
                          open={false}
                          style={{ pointerEvents: 'none', cursor: 'default' }}
                          ref={(node) => {
                            dropdownMenu = node;
                          }}
                        >
                          {names(dataseta).map((d) => {
                            const index = ADIList.indexOf(d);
                            return (
                              <MenuItem style={{ fontWeight: '500' }} eventKey={d}>
                                <div
                                  className="dropdownText"
                                  style={{
                                    borderRadius:
                                      checkedState[index] &&
                                      checkedState[index + 1] &&
                                      checkedState[index - 1]
                                        ? '0px 0px 0px 0px'
                                        : checkedState[index] && checkedState[index - 1]
                                        ? '0px 0px 8px 8px'
                                        : checkedState[index] && checkedState[index + 1]
                                        ? '8px 8px 0px 0px'
                                        : '8px',
                                    background: checkedState[index]
                                      ? 'rgba(211, 107, 40, 0.1)'
                                      : '#FFF'
                                  }}
                                >
                                  <input
                                    key={d + checkedState[index]}
                                    type="checkbox"
                                    name={`${d}`}
                                    index={index}
                                    className="checkbox-neo"
                                    style={{ marginRight: 5 }}
                                    checked={checkedState[index]}
                                    onChange={(event) => {
                                      handleSelectBox(index);
                                    }}
                                  />
                                  <text
                                    className="ADIText"
                                    index={index}
                                    style={{
                                      color: checkedState[index] ? '#D36B28' : '#5E5F5F',
                                      fontWeight: '500'
                                    }}
                                  >
                                    {d}
                                  </text>
                                </div>
                              </MenuItem>
                            );
                          })}
                        </Dropdown.SubMenu>
                      </MenuItem>
                    </CustomDropdownMenu>
                  </CustomDropdown>
                </div>
              </div>
              {/* Economic Class */}
              <div className="Frame11" style={{ width: '420px' }}>
                <text className="Institution">Economic Class</text>
                <div className="Frame1_2" ref={ref}>
                  <CustomDropdown
                    open={isEconomicClassOpen}
                    onClick={() => {
                      setIsEconomicClassOpen(!isEconomicClassOpen);
                      setIsInstitutionOpen(false);
                      setIsAssetsOpen(false);
                      setIsDepositsOpen(false);
                      setIsLiabilitiesOpen(false);
                      setIsLoansOpen(false);
                    }}
                  >
                    <CustomDropdownToggle noCaret title={''} style={{ width: '420px' }}>
                      <img
                        className="bankOutline"
                        alt=""
                        src={economicClassIcon}
                        height="24"
                        width="24"
                      />
                      <text
                        className="customDropdownToggleText"
                        style={{
                          position: 'relative',
                          left: '45px',
                          top: '1px',
                          color: economicClassFontColor
                        }}
                      >
                        {economicClass ? `${economicClass}` : 'Economic Class'}
                      </text>
                      <img
                        className="arrowDropdown"
                        alt=""
                        src={economicClassFontColor === '#D36B28' ? arrowIconOrange : arrowIcon}
                        height="24"
                        width="24"
                      />
                    </CustomDropdownToggle>
                    <CustomDropdownMenu
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <MenuItem
                        onClick={(event) => {
                          setIsAssetsOpen(!isAssetsOpen);
                          setIsDepositsOpen(false);
                          setIsLiabilitiesOpen(false);
                          setIsLoansOpen(false);
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                        style={{ cursor: 'default', paddingLeft: '0px' }}
                        eventKey="Big 4"
                      >
                        <div
                          className="dropdownHeading"
                          onMouseEnter={() => {
                            handleOnMouseEnter(false, true, false, false, false);
                          }}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            height: '32px'
                          }}
                        >
                          <text
                            style={{
                              color: isAssetsOpen ? '#D36B28' : '#5E5F5F',
                              fontWeight: '600',
                              position: 'relative',
                              width: '130px',
                              paddingLeft: '8px'
                            }}
                          >
                            Assets
                          </text>
                          <img
                            className="arrowSubMenu"
                            alt=""
                            src={isAssetsOpen ? arrowIconOrange : arrowIcon}
                            height="24"
                            width="24"
                          />
                        </div>
                        <Dropdown.SubMenu
                          open={isAssetsOpen}
                          style={{ pointerEvents: 'none', cursor: 'default' }}
                        >
                          <div
                            style={{ pointerEvents: 'none', cursor: 'default', width: '330px' }}
                            onClick={(event) => {
                              handleEconomicClassChange(event, 'assets');
                              setIsEconomicClassOpen(false);
                              event.preventDefault();
                              event.stopPropagation();
                            }}
                          >
                            {mobile && (
                              <div className="mobileHeadingMenu">
                                Assets
                                <button
                                  id="return"
                                  className="mobileButton"
                                  onClick={() => {
                                    setIsAssetsOpen(false);
                                  }}
                                >
                                  <img src="/static/icons/arrow-left.svg" />
                                </button>
                              </div>
                            )}
                            <div className="scroll_container" style={{ height: '270px' }}>
                              <div className="scroll_content">
                                <Scrollbars
                                  renderTrackHorizontal={(props) => (
                                    <div
                                      {...props}
                                      style={{ display: 'none' }}
                                      className="track-horizontal"
                                    />
                                  )}
                                  renderTrackVertical={(props) => (
                                    <div {...props} className="track-vertical" />
                                  )}
                                  renderThumbVertical={(props) => (
                                    <div {...props} className="thumb-vertical" />
                                  )}
                                  style={{ width: '100%' }}
                                >
                                  {types(dataseta).map((d) => {
                                    const index = ADIList.indexOf(d);
                                    return (
                                      <MenuItem
                                        style={{ pointerEvents: 'auto', fontWeight: '500' }}
                                        eventKey={d}
                                      >
                                        <div
                                          className="dropdownText"
                                          style={{
                                            borderRadius: '8px',
                                            background:
                                              economicClass === d
                                                ? 'rgba(211, 107, 40, 0.1)'
                                                : '#FFF'
                                          }}
                                        >
                                          <text
                                            className="ADIText"
                                            index={index}
                                            style={{
                                              color: economicClass === d ? '#D36B28' : '#5E5F5F',
                                              fontWeight: '500'
                                            }}
                                          >
                                            {d}
                                          </text>
                                        </div>
                                      </MenuItem>
                                    );
                                  })}
                                </Scrollbars>
                              </div>
                            </div>
                          </div>
                        </Dropdown.SubMenu>
                      </MenuItem>
                      <MenuItem
                        onClick={(event) => {
                          setIsAssetsOpen(false);
                          setIsDepositsOpen(!isDepositsOpen);
                          setIsLiabilitiesOpen(false);
                          setIsLoansOpen(false);
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                        style={{ cursor: 'default', paddingLeft: '0px' }}
                      >
                        <div
                          className="dropdownHeading"
                          onMouseEnter={() => {
                            handleOnMouseEnter(false, false, true, false, false);
                          }}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            height: '32px'
                          }}
                        >
                          <text
                            style={{
                              color: isDepositsOpen ? '#D36B28' : '#5E5F5F',
                              fontWeight: '600',
                              position: 'relative',
                              width: '130px',
                              paddingLeft: '8px'
                            }}
                          >
                            Deposits
                          </text>
                          <img
                            className="arrowSubMenu"
                            alt=""
                            src={isDepositsOpen ? arrowIconOrange : arrowIcon}
                            height="24"
                            width="24"
                          />
                        </div>
                        <Dropdown.SubMenu
                          open={isDepositsOpen}
                          style={{ pointerEvents: 'none', cursor: 'default' }}
                        >
                          <div
                            style={{ pointerEvents: 'none', cursor: 'default', width: '270px' }}
                            onClick={(event) => {
                              handleEconomicClassChange(event, 'deposits');
                              setIsEconomicClassOpen(false);
                              event.preventDefault();
                              event.stopPropagation();
                            }}
                          >
                            {mobile && (
                              <div className="mobileHeadingMenu">
                                Deposits
                                <button
                                  id="return"
                                  className="mobileButton"
                                  onClick={() => {
                                    setIsDepositsOpen(false);
                                  }}
                                >
                                  <img src="/static/icons/arrow-left.svg" />
                                </button>
                              </div>
                            )}
                            <div className="scroll_container" style={{ height: '240px' }}>
                              <div className="scroll_content">
                                <Scrollbars
                                  renderTrackHorizontal={(props) => (
                                    <div
                                      {...props}
                                      style={{ display: 'none' }}
                                      className="track-horizontal"
                                    />
                                  )}
                                  renderTrackVertical={(props) => (
                                    <div {...props} className="track-vertical" />
                                  )}
                                  renderThumbVertical={(props) => (
                                    <div {...props} className="thumb-vertical" />
                                  )}
                                  style={{ width: '100%' }}
                                >
                                  {types(datasetd).map((d) => {
                                    const index = ADIList.indexOf(d);
                                    return (
                                      <MenuItem
                                        style={{ pointerEvents: 'auto', fontWeight: '500' }}
                                        eventKey={d}
                                      >
                                        <div
                                          className="dropdownText"
                                          style={{
                                            borderRadius: '8px',
                                            background:
                                              economicClass === d
                                                ? 'rgba(211, 107, 40, 0.1)'
                                                : '#FFF'
                                          }}
                                        >
                                          <text
                                            className="ADIText"
                                            index={index}
                                            style={{
                                              color: economicClass === d ? '#D36B28' : '#5E5F5F',
                                              fontWeight: '500'
                                            }}
                                          >
                                            {d}
                                          </text>
                                        </div>
                                      </MenuItem>
                                    );
                                  })}
                                </Scrollbars>
                              </div>
                            </div>
                          </div>
                        </Dropdown.SubMenu>
                      </MenuItem>
                      <MenuItem
                        onClick={(event) => {
                          setIsAssetsOpen(false);
                          setIsDepositsOpen(false);
                          setIsLiabilitiesOpen(!isLiabilitiesOpen);
                          setIsLoansOpen(false);
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                        style={{ cursor: 'default', paddingLeft: '0px' }}
                      >
                        <div
                          className="dropdownHeading"
                          onMouseEnter={() => {
                            handleOnMouseEnter(false, false, false, true, false);
                          }}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            height: '32px'
                          }}
                        >
                          <text
                            style={{
                              color: isLiabilitiesOpen ? '#D36B28' : '#5E5F5F',
                              fontWeight: '600',
                              position: 'relative',
                              width: '130px',
                              paddingLeft: '8px'
                            }}
                          >
                            Liabilities
                          </text>
                          <img
                            className="arrowSubMenu"
                            alt=""
                            src={isLiabilitiesOpen ? arrowIconOrange : arrowIcon}
                            height="24"
                            width="24"
                          />
                        </div>
                        <Dropdown.SubMenu
                          open={isLiabilitiesOpen}
                          style={{ pointerEvents: 'none', cursor: 'default' }}
                        >
                          <div
                            style={{ pointerEvents: 'none', cursor: 'default', width: '270px' }}
                            onClick={(event) => {
                              handleEconomicClassChange(event, 'liabilities');
                              setIsEconomicClassOpen(false);
                              event.preventDefault();
                              event.stopPropagation();
                            }}
                          >
                            {mobile && (
                              <div className="mobileHeadingMenu">
                                Liabilities
                                <button
                                  id="return"
                                  className="mobileButton"
                                  onClick={() => {
                                    setIsLiabilitiesOpen(false);
                                  }}
                                >
                                  <img src="/static/icons/arrow-left.svg" />
                                </button>
                              </div>
                            )}
                            <div className="scroll_container" style={{ height: '240px' }}>
                              <div className="scroll_content">
                                <Scrollbars
                                  renderTrackHorizontal={(props) => (
                                    <div
                                      {...props}
                                      style={{ display: 'none' }}
                                      className="track-horizontal"
                                    />
                                  )}
                                  renderTrackVertical={(props) => (
                                    <div {...props} className="track-vertical" />
                                  )}
                                  renderThumbVertical={(props) => (
                                    <div {...props} className="thumb-vertical" />
                                  )}
                                  style={{ width: '100%' }}
                                >
                                  {types(datasetl).map((d) => {
                                    const index = ADIList.indexOf(d);
                                    return (
                                      <MenuItem
                                        style={{ pointerEvents: 'auto', fontWeight: '500' }}
                                        eventKey={d}
                                      >
                                        <div
                                          className="dropdownText"
                                          style={{
                                            borderRadius: '8px',
                                            background:
                                              economicClass === d
                                                ? 'rgba(211, 107, 40, 0.1)'
                                                : '#FFF'
                                          }}
                                        >
                                          <text
                                            className="ADIText"
                                            index={index}
                                            style={{
                                              color: economicClass === d ? '#D36B28' : '#5E5F5F',
                                              fontWeight: '500'
                                            }}
                                          >
                                            {d}
                                          </text>
                                        </div>
                                      </MenuItem>
                                    );
                                  })}
                                </Scrollbars>
                              </div>
                            </div>
                          </div>
                        </Dropdown.SubMenu>
                      </MenuItem>
                      <MenuItem
                        onClick={(event) => {
                          setIsAssetsOpen(false);
                          setIsDepositsOpen(false);
                          setIsLiabilitiesOpen(false);
                          setIsLoansOpen(!isLoansOpen);
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                        style={{ cursor: 'default', paddingLeft: '0px' }}
                      >
                        <div
                          className="dropdownHeading"
                          onMouseEnter={() => {
                            handleOnMouseEnter(false, false, false, false, true);
                          }}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            height: '32px'
                          }}
                        >
                          <text
                            style={{
                              color: isLoansOpen ? '#D36B28' : '#5E5F5F',
                              fontWeight: '600',
                              position: 'relative',
                              width: '130px',
                              paddingLeft: '8px'
                            }}
                          >
                            Loans
                          </text>
                          <img
                            className="arrowSubMenu"
                            alt=""
                            src={isLoansOpen ? arrowIconOrange : arrowIcon}
                            height="24"
                            width="24"
                          />
                        </div>
                        <Dropdown.SubMenu
                          open={isLoansOpen}
                          style={{ pointerEvents: 'none', cursor: 'default' }}
                        >
                          <div
                            style={{ pointerEvents: 'none', cursor: 'default', width: '300px' }}
                            onClick={(event) => {
                              handleEconomicClassChange(event, 'loans');
                              setIsEconomicClassOpen(false);
                              event.preventDefault();
                              event.stopPropagation();
                            }}
                          >
                            {mobile && (
                              <div className="mobileHeadingMenu">
                                Loans
                                <button
                                  id="return"
                                  className="mobileButton"
                                  onClick={() => {
                                    setIsLoansOpen(false);
                                  }}
                                >
                                  <img src="/static/icons/arrow-left.svg" />
                                </button>
                              </div>
                            )}
                            <div className="scroll_container" style={{ height: '390px' }}>
                              <div className="scroll_content">
                                <Scrollbars
                                  renderTrackHorizontal={(props) => (
                                    <div
                                      {...props}
                                      style={{ display: 'none' }}
                                      className="track-horizontal"
                                    />
                                  )}
                                  renderTrackVertical={(props) => (
                                    <div {...props} className="track-vertical" />
                                  )}
                                  renderThumbVertical={(props) => (
                                    <div {...props} className="thumb-vertical" />
                                  )}
                                  style={{ width: '100%' }}
                                >
                                  {types(datasetlo).map((d) => {
                                    const index = ADIList.indexOf(d);
                                    return (
                                      <MenuItem
                                        style={{ pointerEvents: 'auto', fontWeight: '500' }}
                                        eventKey={d}
                                      >
                                        <div
                                          className="dropdownText"
                                          style={{
                                            borderRadius: '8px',
                                            background:
                                              economicClass === d
                                                ? 'rgba(211, 107, 40, 0.1)'
                                                : '#FFF'
                                          }}
                                        >
                                          <text
                                            className="ADIText"
                                            index={index}
                                            style={{
                                              color: economicClass === d ? '#D36B28' : '#5E5F5F',
                                              fontWeight: '500'
                                            }}
                                          >
                                            {d}
                                          </text>
                                        </div>
                                      </MenuItem>
                                    );
                                  })}
                                </Scrollbars>
                              </div>
                            </div>
                          </div>
                        </Dropdown.SubMenu>
                      </MenuItem>
                    </CustomDropdownMenu>
                  </CustomDropdown>
                </div>
              </div>
              <div className="Frame6">
                <button
                  className="ResetFilters"
                  onClick={(event) => {
                    handleResetFilters(event);
                  }}
                >
                  Reset filters
                </button>
              </div>
            </div>
            <div className="graphBackground">
              <div className="graphHeading">
                <div className="graphFrame">
                  <text className="graphType">Graph type</text>
                  {mobile ? (
                    <CustomSwitchMobile
                    defaultChecked
                      onChange={(event) => {
                        handleSwitchChange(event.target.value);
                      }}
                    />
                  ) : (
                    <CustomSwitch
                    defaultChecked
                      onChange={(event) => {
                        handleSwitchChange(event.target.value);
                      }}
                    />
                  )}
                </div>
                {!isLineChart && (
                  <div className="timelineFrame">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                        right: '0'
                      }}
                    >
                      <text className="graphType"> View timeline simulation</text>
                      {mobile ? (
                        <CustomSliderMobile
                          id="timeline"
                          defaultValue={sliderValue}
                          sx={{ width: '40vw', maxWidth: '400px' }}
                          aria-label="Default"
                          min={3}
                          step={1}
                          max={[...new Set(dataseta.map((d) => d.Period))].length + (3 - 1)} // Number of unique dates
                          scale={calculateValue}
                          valueLabelFormat={valueLabelFormat}
                          getAriaValueText={valueLabelFormat}
                          valueLabelDisplay="auto"
                        />
                      ) : (
                        <CustomSlider
                          id="timeline"
                          defaultValue={sliderValue}
                          sx={{ width: '40vw', maxWidth: '400px' }}
                          aria-label="Default"
                          min={3}
                          step={1}
                          max={[...new Set(dataseta.map((d) => d.Period))].length + (3 - 1)} // Number of unique dates
                          scale={calculateValue}
                          valueLabelFormat={valueLabelFormat}
                          getAriaValueText={valueLabelFormat}
                          valueLabelDisplay="auto"
                        />
                      )}
                    </div>
                    <div className="timelineFrame2">
                      <div style={{ display: 'flex', flexDirection: 'row', gap: '0px' }}>
                        <button id="reset" className="playButton">
                          <img src="/static/icons/rewindButton.svg" />
                        </button>
                        <button id="start" className="playButton">
                          <img id="playImg" src="/static/icons/playButton.svg" />
                        </button>
                        {/*!playButtonState ? <button id="start" className="playButton" onClick={() => setPlayButtonState(!playButtonState)}>
                      <img src="/static/icons/playButton.svg" />
                    </button> 
                    :
                    <button id="pause" className="playButton" onClick={() => setPlayButtonState(!playButtonState)}>
                      <img src="/static/icons/pauseButton.svg" />
                </button>*/}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: mobile ? '0px' : '8px',
                          position: 'relative',
                          right: '0px'
                        }}
                        ref={refMonths}
                      >
                        <CustomDropdown
                          open={isYearOpen}
                          onClick={() => {
                            setIsYearOpen(!isYearOpen);
                            setIsMonthOpen(false);
                          }}
                        >
                          <CustomDropdownToggle
                            style={{
                              maxWidth: mobile ? '51px' : '80px',
                              height: mobile ? '20px' : '30px',
                              borderRadius: '16px'
                            }}
                          >
                            <text
                              ref={(node) => {
                                yearText = node;
                              }}
                              id="yearText"
                              className="timelineSelectsText"
                              style={{ position: 'relative', left: '5px', top: '1px' }}
                            >
                              {selectedYear ? `${selectedYear}` : 'Year'}
                            </text>
                            <img
                              className="arrowDropdown"
                              alt=""
                              style={{
                                position: 'absolute',
                                right: mobile ? '2px' : '9px',
                                top: mobile ? '2px' : '8px'
                              }}
                              src={arrowIcon}
                              height="12"
                              width="12"
                            />
                          </CustomDropdownToggle>
                          <CustomDropdownMenuTimeline>
                            <div className="scroll_container" style={{ height: '150px' }}>
                              <div className="scroll_content_timeline">
                                <Scrollbars
                                  renderTrackHorizontal={(props) => (
                                    <div
                                      {...props}
                                      style={{ display: 'none' }}
                                      className="track-horizontal"
                                    />
                                  )}
                                  renderTrackVertical={(props) => (
                                    <div {...props} className="track-vertical" />
                                  )}
                                  renderThumbVertical={(props) => (
                                    <div {...props} className="thumb-vertical" />
                                  )}
                                  style={{ width: '100%' }}
                                >
                                  {years(dataseta, selectedYear).map((d) => {
                                    return (
                                      <MenuItem
                                        onClick={(event) => {
                                          setSelectedYear(event.target.innerText);
                                          setYearTrigger(!yearTrigger);
                                          // Get current month of drop-down box
                                          let currentMonth =
                                            ReactDOM.findDOMNode(monthText).innerText;
                                          if (
                                            months(dataseta, event.target.innerText).indexOf(
                                              currentMonth
                                            ) === -1
                                          ) {
                                            // If currently selected month does not exist, reset month to the latest month available
                                            setSelectedMonth(
                                              months(dataseta, event.target.innerText).pop()
                                            );
                                          } else {
                                            setSelectedMonth(currentMonth);
                                          }
                                          setIsYearOpen(!isYearOpen);
                                          event.preventDefault();
                                          event.stopPropagation();
                                        }}
                                        style={{ pointerEvents: 'auto', fontWeight: '500' }}
                                        eventKey={d}
                                      >
                                        <div
                                          className="dropdownTextTimeline"
                                          style={{
                                            borderRadius: '8px',
                                            background: selectedYear == d ? '#FFF' : '#FFF'
                                          }}
                                        >
                                          <text
                                            style={{
                                              color: '#8B8B8B',
                                              fontWeight: '500',
                                              position: 'relative',
                                              width: '80px',
                                              paddingLeft: '0px'
                                            }}
                                          >
                                            {d}
                                          </text>
                                        </div>
                                      </MenuItem>
                                    );
                                  })}
                                </Scrollbars>
                              </div>
                            </div>
                          </CustomDropdownMenuTimeline>
                        </CustomDropdown>
                        <CustomDropdown
                          open={isMonthOpen}
                          onClick={() => {
                            setIsYearOpen(false);
                            setIsMonthOpen(!isMonthOpen);
                          }}
                        >
                          <CustomDropdownToggle
                            style={{
                              maxWidth: mobile ? '51px' : '80px',
                              height: mobile ? '20px' : '30px',
                              borderRadius: '16px'
                            }}
                          >
                            <text
                              ref={(node) => {
                                monthText = node;
                              }}
                              id="monthText"
                              className="timelineSelectsText"
                              style={{ position: 'relative', left: '5px', top: '1px' }}
                            >
                              {selectedMonth ? `${selectedMonth}` : ''}
                            </text>
                            <img
                              className="arrowDropdown"
                              alt=""
                              style={{
                                position: 'absolute',
                                right: mobile ? '2px' : '9px',
                                top: mobile ? '2px' : '8px'
                              }}
                              src={arrowIcon}
                              height={mobile ? '10' : '12'}
                              width={mobile ? '10' : '12'}
                            />
                          </CustomDropdownToggle>
                          <CustomDropdownMenuTimeline>
                            <div className="scroll_container" style={{ height: '150px' }}>
                              <div className="scroll_content_timeline">
                                <Scrollbars
                                  renderTrackHorizontal={(props) => (
                                    <div
                                      {...props}
                                      style={{ display: 'none' }}
                                      className="track-horizontal"
                                    />
                                  )}
                                  renderTrackVertical={(props) => (
                                    <div {...props} className="track-vertical" />
                                  )}
                                  renderThumbVertical={(props) => (
                                    <div {...props} className="thumb-vertical" />
                                  )}
                                  style={{ width: '100%' }}
                                >
                                  {months(dataseta, selectedYear).map((d) => {
                                    return (
                                      <MenuItem
                                        onClick={(event) => {
                                          setSelectedMonth(event.target.innerText);
                                          setMonthTrigger(!monthTrigger);
                                          // Get current month of drop-down box
                                          let currentYear =
                                            ReactDOM.findDOMNode(yearText).innerText;
                                          setSelectedYear(currentYear);
                                          setIsMonthOpen(false);
                                          event.preventDefault();
                                          event.stopPropagation();
                                        }}
                                        style={{ pointerEvents: 'auto', fontWeight: '500' }}
                                        eventKey={d}
                                      >
                                        <div
                                          className="dropdownTextTimeline"
                                          style={{
                                            borderRadius: '8px',
                                            background: selectedMonth === d ? '#FFF' : '#FFF'
                                          }}
                                        >
                                          <text
                                            style={{
                                              color: '#8B8B8B',
                                              fontWeight: '500',
                                              position: 'relative',
                                              width: '80px',
                                              paddingLeft: '0px'
                                            }}
                                          >
                                            {d}
                                          </text>
                                        </div>
                                      </MenuItem>
                                    );
                                  })}
                                </Scrollbars>
                              </div>
                            </div>
                          </CustomDropdownMenuTimeline>
                        </CustomDropdown>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {false && <text className="graphType">View timeline simulation</text>}
              <div id="App-header" className="App-header">
                {!isLineChart ? (
                  <MemoizedBarGraph
                    ADIs={selectedADI}
                    economicClass={economicClass ? economicClass : 'Total residents assets'}
                    economicCategory={economicCategory ? economicCategory : 'assets'}
                    assets={dataseta}
                    deposits={datasetd}
                    liabilities={datasetl}
                    loans={datasetlo}
                    month={monthsArray.indexOf(selectedMonth) + 1}
                    year={selectedYear}
                    yearTrigger={yearTrigger}
                    monthTrigger={monthTrigger}
                  />
                ) : (
                  <MemoizedLineGraph
                    ADIs={selectedADI}
                    economicClass={economicClass ? economicClass : 'Total residents assets'}
                    economicCategory={economicCategory ? economicCategory : 'assets'}
                    assets={datasetal}
                    deposits={datasetdl}
                    liabilities={datasetll}
                    loans={datasetlol}
                  />
                )}
              </div>
            </div>
          </div>
        )}
    </main>
  );
}

function BarGraph({
  ADIs,
  economicClass,
  economicCategory,
  assets,
  deposits,
  liabilities,
  loans,
  month,
  year,
  yearTrigger,
  monthTrigger
}) {
  return (
    <BarChartRace
      ADIs={ADIs}
      economicClass={economicClass ? economicClass : 'Total residents assets'}
      economicCategory={economicCategory ? economicCategory : 'assets'}
      assets={assets}
      deposits={deposits}
      liabilities={liabilities}
      loans={loans}
      month={month}
      year={year}
      yearTrigger={yearTrigger}
      monthTrigger={monthTrigger}
    />
  );
}

const MemoizedBarGraph = React.memo(BarGraph);

function LineGraph({
  ADIs,
  economicClass,
  economicCategory,
  assets,
  deposits,
  liabilities,
  loans,
  width,
  height
}) {
  return (
    <LineChart
      ADIs={ADIs}
      economicClass={economicClass ? economicClass : 'Total residents assets'}
      economicCategory={economicCategory ? economicCategory : 'assets'}
      assets={assets}
      deposits={deposits}
      liabilities={liabilities}
      loans={loans}
      width={width}
      height={height}
    />
  );
}

const MemoizedLineGraph = React.memo(LineGraph);

function valueLabelFormat(value) {
  const year = value[0];
  const monthIndex = value[1] - 1;
  const monthName = monthsArray[monthIndex];

  return `${monthName} ${year}`;
}

function calculateValue(value) {
  const year = 19 + Math.floor(value / 13);
  const month = value % 12 === 0 ? 12 : value % 12;
  return [year, month];
}
