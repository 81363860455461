export const newKeysAssets = {
  ADI: 'ADI',
  o: 'type',
  t: 'Period',
  m: 'prediction',
  a: 'Cash and deposits with financial institutions',
  au: 'Cash and deposits with financial institutions upper',
  al: 'Cash and deposits with financial institutions lower',
  b: 'Trading securities',
  bu: 'Trading securities upper',
  bl: 'Trading securities lower',
  c: 'Investment securities',
  cu: 'Investment securities upper',
  cl: 'Investment securities lower',
  d: 'Net acceptances of customers',
  du: 'Net acceptances of customers upper',
  dl: 'Net acceptances of customers lower',
  e: 'Total residents assets',
  eu: 'Total residents assets upper',
  el: 'Total residents assets lower',
  f: 'Total securitised assets on balance sheet',
  fu: 'Total securitised assets on balance sheet upper',
  fl: 'Total securitised assets on balance sheet lower',
  g: 'Total residents loans and finance leases',
  gu: 'Total residents loans and finance leases upper',
  gl: 'Total residents loans and finance leases lower',
  et: 'eventType',
  ei: 'eventInfo'
};
export const newKeysLoans = {
  ADI: 'ADI',
  o: 'type',
  t: 'Period',
  m: 'prediction',
  a: 'Non-financial businesses',
  au: 'Non-financial businesses upper',
  al: 'Non-financial businesses lower',
  b: 'Financial institutions',
  bu: 'Financial institutions upper',
  bl: 'Financial institutions lower',
  c: 'General government',
  cu: 'General government upper',
  cl: 'General government lower',
  d: 'Housing: Owner-occupied',
  du: 'Housing: Owner-occupied upper',
  dl: 'Housing: Owner-occupied lower',
  e: 'Housing: Investment',
  eu: 'Housing: Investment upper',
  el: 'Housing: Investment lower',
  f: 'Credit cards',
  fu: 'Credit cards upper',
  fl: 'Credit cards lower',
  g: 'Other',
  gu: 'Other upper',
  gl: 'Other lower',
  h: 'Community service organisations',
  hu: 'Community service organisations upper',
  hl: 'Community service organisations lower',
  i: 'Total residents loans and finance leases',
  iu: 'Total residents loans and finance leases upper',
  il: 'Total residents loans and finance leases lower',
  j: 'Intra-group loans and finance leases',
  ju: 'Intra-group loans and finance leases upper',
  jl: 'Intra-group loans and finance leases lower',
  et: 'eventType',
  ei: 'eventInfo'
};
export const newKeysDeposits = {
  ADI: 'ADI',
  o: 'type',
  t: 'Period',
  m: 'prediction',
  a: 'Non-financial businesses',
  au: 'Non-financial businesses upper',
  al: 'Non-financial businesses lower',
  b: 'Financial institutions',
  bu: 'Financial institutions upper',
  bl: 'Financial institutions lower',
  c: 'General government',
  cu: 'General government upper',
  cl: 'General government lower',
  d: 'Households',
  du: 'Households upper',
  dl: 'Households lower',
  e: 'Community service organisations',
  eu: 'Community service organisations upper',
  el: 'Community service organisations lower',
  f: 'Total residents deposits',
  fu: 'Total residents deposits upper',
  fl: 'Total residents deposits lower',
  et: 'eventType',
  ei: 'eventInfo'
};

export const newKeysLiabilities = {
  ADI: 'ADI',
  o: 'type',
  t: 'Period',
  m: 'prediction',
  a: 'Acceptances',
  au: 'Acceptances upper',
  al: 'Acceptances lower',
  b: 'Total residents deposits',
  bu: 'Total residents deposits upper',
  bl: 'Total residents deposits lower',
  c: 'Intra-group deposits',
  cu: 'Intra-group deposits upper',
  cl: 'Intra-group deposits lower',
  d: 'Negotiable Certificates of Deposit',
  du: 'Negotiable Certificates of Deposit upper',
  dl: 'Negotiable Certificates of Deposit lower',
  e: 'Total short-term borrowings',
  eu: 'Total short-term borrowings upper',
  el: 'Total short-term borrowings lower',
  f: 'Total long-term borrowings',
  fu: 'Total long-term borrowings upper',
  fl: 'Total long-term borrowings lower',
  et: 'eventType',
  ei: 'eventInfo'
};
