import React from 'react';

// ----------------------------------------------------------------------

export default function LearnMore() {
  const link = "https://www.neo-analytics.com.au/"
  const contactUs = <a className="contactus" href={link} target="_blank" rel="noopener noreferrer">contact us</a>;
  return (
    <main className="main" style={{flex: 'none', margin: '1vw 1vw 1vw 1vw'}}>
      { //<img src="/static/icons/Visit_Neo.jpg" style={{objectFit: "cover", objectPosition: "100% 85%", borderRadius: "12px", width: "96%", maxHeight: "200px", marginTop: "24px", alignSelf: "center"}}/>
      }
      <div className="LearnMoreOuter">
        <text className="Performance">Learn more</text>
        <div className="LearnMoreInfo">
          <text className="LearnMoreInfoText"> With expertise across core banking systems, regulations, data science, data engineering and machine learning, </text>
          <text className="LearnMoreInfoText"> our team is equipped to take on any big data challenge.</text>
          
          <div className="LearnMoreInfo2"> 
            <text className="LearnMoreInfoText"> To learn more about what we can do to help your organization</text>
            <button className="VisitOurWebsiteButton" onClick={() => {window.open(link, "_blank")}}>
                <text className="VisitOurWebsiteText">Visit Our Website</text>
            </button>
          </div>
          <text className="LearnMoreInfoText">Or simply {contactUs} to talk to us directly</text>
        </div>
      </div>
    </main>
  );
}

/* 
body {
  background-color: #000000cc;
}
.App {
  background-color: #000000;
  border: 1px solid white;
  display: inline-block;
}
*/
