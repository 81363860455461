// authentication
import { Authenticator, useTheme, Image, View, Button, Heading, useAuthenticator } from '@aws-amplify/ui-react';
import Amplify from 'aws-amplify';
import { I18n } from 'aws-amplify';
import '../node_modules/@aws-amplify/ui-react/dist/styles.css';
// Get the aws resources configuration parameters
import awsconfig from './aws-exports'; // if you are using Amplify CLI
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import './design.css';
// ----------------------------------------------------------------------
Amplify.configure(awsconfig);

I18n.putVocabulariesForLanguage('en', {
  'Sign In': 'Login', // Tab header
  'Sign in': 'Login', // Button label
  'Sign in to your account': 'Welcome Back!',
  'Enter your password' : 'Password',
  Username: 'Enter your username', // Username label
  Password: 'Password', // Password label
  'Forgot your password?': 'Forgot password?',
  'Create Account': 'Submit',
});

const formFields = {
  signIn: {
    username: {
      order:1,
      isRequired: true,
      label: 'Email',
      labelHidden: false,
      placeholder: ''
    },
    password: {
      order: 2,
      isRequired: true,
      label: 'Password',
      labelHidden: false,
      placeholder: ''
    },
  },
  signUp: {
    name: {
      order:1,
      // placeholder: 'Name',
      isRequired: true,
      label: 'Name',
    },
    email: {
      order:2,
      isRequired: true,
      label: 'Email',
      labelHidden: false,
      placeholder: ''
    },
    zoneinfo: {
      order: 3,
      isRequired: true,
      label: 'Organisation',
      labelHidden: false,
      placeholder: ''
    },
    password: {
      order: 0,
      isRequired: false,
      label: 'Password',
      labelHidden: false,
      value: 'password',
    },
    confirm_password: {
      order: 0,
      isRequired: false,
      label: 'Confirm Password',
      labelHidden: false,
      value: 'password',
    }
  },
 }

const components = {
  /*Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Neo logo"
          src="https://www.neo-analytics.com.au/wp-content/uploads/neo-analytics.svg"
        />
      </View>
    );
  },*/
  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} ${tokens.space.xl} 0 ${tokens.space.xl}`}
          level={3}
        >
          <div className="signUp">
            <Image
            alt="Neo logo"
            src="https://www.neo-analytics.com.au/wp-content/uploads/neo-analytics.svg"
            style={{width: '50%', marginBottom: "5%", maxHeight: "84px", maxWidth: "220px"}}
            />
            <text className="signUpHeading">
              Welcome to Neo Analytic's free ADI Performance Tracker
            </text>
            <text className="signUpInfo">
              This smart BI tool can help you get insights into the state of the ADI market at any given point in time, including virtual merger simulations.
            </text>
          </div>
        </Heading>
      );
    },
    Footer() {
      const { toSignUp } = useAuthenticator();
      const { toResetPassword } = useAuthenticator();

      return (
        <div>
        <View textAlign="center">
          <text className="signUpFooter">Don't have an account?</text>
          <Button
            fontWeight="normal"
            onClick={toSignUp}
            size="small"
            variation="link"
          >
            Register
          </Button>
        </View>
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
            style={{color: '#8B8B8B', textDecorationLine: 'underline'}}
          >
            forgot password?
          </Button>
        </View>
        </div>
      );
    },
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} ${tokens.space.xl} 0 ${tokens.space.xl}`}
          level={3}
        >
          {/* no private preview
          <div className="signUp">
            <Image
            alt="Neo logo"
            src="https://www.neo-analytics.com.au/wp-content/uploads/neo-analytics.svg"
            style={{width: '50%', marginBottom: "5%", maxHeight: "84px", maxWidth: "220px"}}
            />
            <text className="signUpHeading">
              Welcome to Neo Analytic's free ADI Performance Tracker
            </text>
            <text className="signUpInfo">
              This smart BI tool can help you get insights into the state of the ADI market at any given point in time, including virtual merger simulations.
            </text>
          </div> */}
          <div className="signUp">
            <Image
            alt="Neo logo"
            src="https://www.neo-analytics.com.au/wp-content/uploads/neo-analytics.svg"
            style={{width: '50%', marginBottom: "5%", maxHeight: "84px", maxWidth: "220px"}}
            />
            <text className="signUpHeading">
              Welcome to Neo Analytic's free ADI Performance Tracker
            </text>
            <text className="signUpInfo" style={{fontSize: '18px'}}>
            Neo Analytics’ ADI Performance Tracker is currently in private preview. If you would like to gain access to the preview, please fill out the form below.
            </text>
          </div>
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center" style={{alignSelf: 'flex-end', marginBottom: '0.5rem'}}>
          <text className="signUpFooter">Already have an account?</text>
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
          >
            Sign in
          </Button>
        </View>
      );
    },
  },
  ResetPassword: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 0`}
          level={3}
        >
          <div className="signUp">
            <Image
            alt="Neo logo"
            src="https://www.neo-analytics.com.au/wp-content/uploads/neo-analytics.svg"
            style={{width: '50%', marginBottom: "5%", maxHeight: "84px", maxWidth: "220px"}}
            />
            <text className="signUpHeading">
              Welcome to Neo Analytic's free ADI Performance Tracker
            </text>
            <text className="signUpInfo" style={{marginBottom: '6vh'}}>
              This smart BI tool can help you get insights into the state of the ADI market at any given point in time, including virtual merger simulations.
            </text>
          </div>
        </Heading>
      );
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 0`}
          level={3}
        >
          <div className="signUp">
            <Image
            alt="Neo logo"
            src="https://www.neo-analytics.com.au/wp-content/uploads/neo-analytics.svg"
            style={{width: '50%', marginBottom: "5%", maxHeight: "84px", maxWidth: "220px"}}
            />
            <text className="signUpHeading">
              Welcome to Neo Analytic's free ADI Performance Tracker
            </text>
            <text className="signUpInfo" style={{marginBottom: '6vh'}}>
              This smart BI tool can help you get insights into the state of the ADI market at any given point in time, including virtual merger simulations.
            </text>
          </div>
        </Heading>
      );
    },
  },
  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 0`}
          level={3}
        >
          <div className="signUp">
            <Image
            alt="Neo logo"
            src="https://www.neo-analytics.com.au/wp-content/uploads/neo-analytics.svg"
            style={{width: '50%', marginBottom: "5%", maxHeight: "84px", maxWidth: "220px"}}
            />
            <text className="signUpHeading">
              Welcome to Neo Analytic's free ADI Performance Tracker
            </text>
            <text className="signUpInfo" style={{marginBottom: '6vh'}}>
              This smart BI tool can help you get insights into the state of the ADI market at any given point in time, including virtual merger simulations.
            </text>
            <text className="signUpInfo" style={{fontSize: '18px', marginBottom: '6vh'}}>
              Thank you for expressing interest in the ADI Performance Tracker, we will get back to you as soon as possible. 
            </text>
          </div>
          <div className="signUp">
            <a href="https://www.neo-analytics.com.au/" rel="noreferrer" style={{alignSelf: 'center'}}>
            <Button
              fontWeight="normal"
              onClick={console.log("test")}
              size="medium"
              variation="link"
              >
              Return home
            </Button>
            </a>
          </div>
        </Heading>
      );
    },
  },
};

export default function Login() {
  return (
    <div className="loginContainer">
      <Authenticator formFields={formFields} loginMechanisms={['email']} align="center" components={components} >
        {({ signOut, user }) => (
          <ThemeConfig>
            <ScrollToTop />
            <GlobalStyles />
            <BaseOptionChartStyle />
            <Router />
          </ThemeConfig>
        )}
      </Authenticator>
      <div className="pliantBackground">
        <text className="disclaimer">
        * The data presented in this app is based on APRA’s monthly ADI statistics. Neo Analytics assumes no responsibility or liability for any errors or omissions in the content of this site. The information contained is provided on an "as is" basis with no guarantees of completeness, accuracy, usefulness, or timeliness.
        </text>
      </div>
    </div>
  );
}
