// authentication
import { useAuthenticator } from '@aws-amplify/ui-react';
import Amplify from 'aws-amplify';
import '../node_modules/@aws-amplify/ui-react/dist/styles.css';
// Get the aws resources configuration parameters
import awsconfig from './aws-exports'; // if you are using Amplify CLI
// components
import Home from './Home';
import Login from './Login';
import './design.css';
// ----------------------------------------------------------------------
Amplify.configure(awsconfig);

export default function App() {
  // Removing requirement for authentication at the moment
  // const { user } = useAuthenticator();

  // if (user) {
  return <Home />;
  //}
  /*
  return <Login />;
  */
}
