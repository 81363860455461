// ----------------------------------------------------------------------
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';

function UserInfo(tree) {
  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    function loadUser() {
      return Auth.currentUserInfo();
    }

    async function onLoad() {
      try {
        const user = await loadUser();
        setUser(user);
      } catch (e) {
        console.log('Error');
      }

      setIsLoading(false);
    }

    onLoad();
  }, []);
  if (tree === 'attributes') {
    return user.attributes;
  }
  return user;
}

export default UserInfo;
