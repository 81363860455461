import PropTypes from 'prop-types';
// material
import { Box, Typography } from '@mui/material';
import '../design.css';
import { boolean } from 'yup';
// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
  navBar: false
};

export default function Logo({ sx, navBar }) {
  if (!navBar) {
    return (
      <Box class="Frame60">
        <Typography class="ADITrackerByHeading" variant="h3">
          ADI Tracker&nbsp;
          <span className="ADITrackerByLabel"> 
          by
          </span>
        </Typography>
        <Box
          component="img"
          src="/static/Neo-Analytics-logo.png"
          sx={{ width: 150, height: 53.7, top: 39, ...sx }} // width is 2.7 times height
        />
      </Box>
    );
  } else {
    return (
      <Box class="Frame60_m">
        <Typography class="ADITrackerByHeading_m" variant="h3">
          ADI Tracker&nbsp;
          <span className="ADITrackerByLabel"> 
          by
          </span>
        </Typography>
        <Box
          component="img"
          src="/static/Neo-Analytics-logo.png"
          sx={{ width: 150, height: 53.7, top: 39, ...sx }} // width is 2.7 times height
        />
      </Box>
    );
  }
}
