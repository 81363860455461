import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Drawer, Avatar, } from '@mui/material';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import sidebarConfig from './SidebarConfig';
import UserInfo from '../../_mocks_/account';

// ----------------------------------------------------------------------
const signOut = () => {
  Auth.signOut()
    .then((data) => console.log(data))
    .catch((err) => console.log(err));
};
const DRAWER_WIDTH = 200;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200]
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // const userInfo = UserInfo();
  let picture = '';
  /*try {
    picture = userInfo.attributes.picture;
  } catch (e) {
    console.log(e);
  }*/

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box sx={{ display: 'inline-flex' }}>
          <a href="https://www.neo-analytics.com.au/" target="_blank" rel="noreferrer">
            <Logo />
          </a>
        </Box>
      </Box>
      
      <hr className="Line4" />
      
      <NavSection navConfig={sidebarConfig} />

      
      <Box class="Frame77" sx={{ mb: 5, mx: 2.5 }}>
        <hr className="Line3" />
        <Link underline="none" component={RouterLink} to="#">
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px'}}>
            {/*<Avatar src={picture} alt="photoURL" />
             Removing requirement to log in for now  
             <div style={{display: 'flex', flexDirection: 'column', alignItems: 'left', width: '90px'}}>
              <text className="username">
                {userInfo.username}
              </text>
              <text onClick={signOut} style={{color: '#8B8B8B', fontWeight: '400', fontSize: '12px', marginTop: '5px', textDecorationLine: 'underline'}} variant="text">
                Log out
              </text>
            </div> */}
           </div>
        </Link>
      </Box>

      <Box sx={{ flexGrow: 1 }} />

    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
      
      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
