/* eslint-disable */
import React from 'react';
import * as d3 from 'd3';
import { useD3 } from '../../../hooks/useD3';
import forceBoundary from 'd3-force-boundary';

const mobileWidth = 480;

function names(data) {
  return new Set(data.map((d) => d.ADI));
}
function monthDiff(d1, d2) {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

function wrap(text, width) {
  text.each(function() {
    var text = d3.select(this),
        words = text.text().split(/\s+/).reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.1, // ems
        y = text.attr("y"),
        dy = parseFloat(text.attr("dy")),
        tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
    while (word = words.pop()) {
      line.push(word);
      tspan.text(line.join(" "));
      if (tspan.node().getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
      }
    }
  });
}

function LineChart({ ADIs, economicClass, economicCategory, assets, deposits, liabilities, loans, width, height}) {
  let datasetRef = assets;
  switch(economicCategory) {
    case "deposits":
      datasetRef = deposits;
      break;
    case "liabilities":
      datasetRef = liabilities;
      break;
    case "loans":
      datasetRef = loans;
      break;
    default:
      datasetRef = assets;
  }
  
  // Get rows where name change event field = name
  const datasetNameChanges = datasetRef.filter((d) => d.eventType === "name" && (ADIs.indexOf(d.ADI) !== -1 || ADIs.indexOf(d.eventInfo) !== -1))
  const nameChanges = [];
  datasetNameChanges.forEach((d) => {
    nameChanges.push({old:d.ADI, new:d['eventInfo'], date:d['Period']});
    if (ADIs) {
      if (ADIs.indexOf(d['eventInfo']) === -1) {
        ADIs.push(d['eventInfo'])
      } 
      if (ADIs.indexOf(d.ADI) === -1) {
        ADIs.push(d.ADI)
      }
    }
  })
  const mergeChanges = [];
  const datasetMergeChanges = datasetRef.filter((d) => d.eventType === "merge" && ADIs.indexOf(d.ADI) !== -1)
  datasetMergeChanges.forEach((d) => {
    mergeChanges.push({old:d.ADI, new:d['eventInfo'], date:d['Period']});
  })
  if (ADIs) {
    datasetRef = datasetRef.filter((d) => ADIs.indexOf(d.ADI) !== -1)
  } else {
    datasetRef = datasetRef.filter((d) => d.type === "Big4");
  }
  let dataset = JSON.parse(JSON.stringify(datasetRef));
  dataset.forEach((d) => {
    const nameEvent = nameChanges.find((x) => x.old === d.ADI);
    const mergeEvent = mergeChanges.find((x) => x.old === d.ADI);
    if (nameEvent) {
      d.ADI = nameEvent.new;
    } else if (mergeEvent) {
    }
  })

  const ref = useD3((svg) => {

    const reDrawWidth = parseInt(d3.select("#App-header").style('width'))
    const reDrawHeight = parseInt(svg.style("height"))
    width = 0.95*reDrawWidth;
    height = reDrawHeight;
    redraw(width, height);
    function redraw(width, height, axisTransition = d3.transition().duration(200), lineTransition = axisTransition.transition().duration(200)) {
      d3.selectAll('option').remove();
      svg.selectAll('*').remove();
      width = width - 24 - 24;
      height = height - 24;

      let ADIs = Array.from(names(dataset));
      let Metric = 'Total residents assets';
      if (economicClass) {
        Metric = economicClass;
      }
      let MetricType = 'assets';
      if (economicCategory) {
        MetricType = economicCategory;
      }
      let ADIType = "";

      let datasetFilter = dataset
      let numADIs = ADIs.length;
      const sAccessor = (d) => d.ADI;
      let dataNest = Array.from(
        d3.group(datasetFilter, (d) => sAccessor(d)),
        ([key, value]) => ({ key, value })
      );
      // Check if qualifies for the We're Sorry dialogue,
      // If number of points with data = 0 === length of that ADI's array.
      let missingDataADIs = [];
      dataNest.forEach((d, i) => {
        const arrayOfZeroClass = d.value.map((data) => data[Metric])
        const isMissingData =  arrayOfZeroClass.filter((data) => data === 0).length === arrayOfZeroClass.length ? true : false;
        if (isMissingData) {
          missingDataADIs.push(d.key);
        }
      });
      // Filter out missing ADIs from data;
      dataNest = dataNest.filter((d) => missingDataADIs.indexOf(d.key) === -1);
      datasetFilter = datasetFilter.filter((d) => missingDataADIs.indexOf(d.ADI) === -1);
      const tParser = d3.timeParse('%Y-%m-%d');
      const tooltipFormatter = (d) => yaxisFormatTooltip(d).replace(/G/, 'B');
      const xAccessor = (d) => {
        if (typeof d === 'undefined') {
          return 0.1;
        }
        return tParser(d.Period);
      };

      let yAccessorUp = (d) => d.type + '_upper';
      let yAccessorDown = (d) => d.type + '_lower';
      let yAccessor = (d) => {
        if (typeof d === 'undefined') {
          return 0.1;
        }
        return d[Metric] * 1000000;
      };

      const dimensions = {
        width: 0.82*width,
        height: height,
        margins: 20,
        rightMargin: -25
      };
      dimensions.ctrWidth = dimensions.width - dimensions.margins - dimensions.rightMargin;
      dimensions.ctrHeight = dimensions.height - dimensions.margins * 1.2;
  
      const colors = ["#D36B28",  "#1E6685", "#CD5059", "#D38228", "#745286", "#57AB9A", "#2F4858", "#005343", "#A94C7B", "#823300"];
      const color = d3.scaleOrdinal(colors.slice(0, numADIs));
      // Draw Image
      const ctr = svg
        .append('g')
        .attr('transform', `translate(${dimensions.margins},${dimensions.margins})`);
      const ctrd = svg
        .append('g')
        .attr('transform', `translate(${dimensions.margins},${dimensions.margins})`);
      const ctre = svg
      .append('g')
      .attr('transform', `translate(${dimensions.margins},${dimensions.margins})`);
      const ctrL = svg
        .append('g')
        .attr('transform', `translate(${dimensions.margins},${dimensions.margins})`);
      const ctrSorry = svg
        .append('g')
        .attr('transform', `translate(0,-40)`);
      let xAxisGroup = ctr
        .append('g')
        .style('transform', `translateY(${dimensions.ctrHeight}px)`)
        .style('font-size', '12px')
        .style('color', '#5E5F5F')
        .style('font-family', 'Urbanist');
      let yAxisGroup = ctr
        .append('g')
        .attr('id', 'y-axis')
        .style('font-size', '12px')
        .style('color', '#D36B28')
        .style('font-family', 'Urbanist');
      const tooltip = d3.select('#tooltip');
      let verticalLine = ctr
        .append('line')
        .style('opacity', 0)
        .style('stroke-width', 1)
        .style('stroke', 'black')
        .style('pointer-events', 'none')
        .style('fill', 'none');
        
      let smartPredictionLine = ctr
      .append('line')
      .style('opacity', 0)
      .style('stroke-width', 1.5)
      .style('stroke', 'black')
      .style('fill', 'none');
      let smartPredictionArea = ctr
        .append('rect');

      const x = d3.scaleUtc().range([0, dimensions.ctrWidth]);

      const y = d3.scaleLinear().range([dimensions.ctrHeight, 0]);
      // Draw Lines

      let linepath = ctr.append('path');
      let areapath = ctr.append('path');
      let sigEvents = ctrd.append('circle');
      let sigEventsLine = ctrd.append('path');
      let linepathTransparent = ctr.append('path');
      let legend = ctrL;
      
      ctr
        .append("svg:image")
        .attr("xlink:href", "/static/icons/confidenceInterval.svg")
        .attr('width', () => {
          const maxWidth = 200;
          if (0.20*width > maxWidth) {
            return maxWidth;
          } else {
            return 0.20*width;
          }
        })
        .style('max-width', '200px')
        .attr('x', dimensions.ctrWidth)
        .attr('y', dimensions.ctrHeight+dimensions.margins/3)
      const xaxisformat = (date) => {
        date.setDate(date.getDate()-1);
        return d3.timeFormat('%b %y')(date);
      }
      const xaxisFormatTooltip = d3.utcFormat('%b %Y');
      const yaxisFormatTooltip = d3.format('0.3s')
      /*(d) => {
        const prefix = d3.formatPrefix('.0f', d)(d);
        return prefix;
      }*/
      const yaxisformat = d3.format('.2s');


      // tooltip function
      function drawTooltip(d, event, dataNest) {
        let mobile = false;
        if (dimensions.ctrWidth <= mobileWidth) {
          mobile = true;
        }
        const mousePos = d3.pointer(event, this);
        const date = x.invert(mousePos[0]);
        // custom Bisector
        let nestIndex = 0;
        const bisector = d3.bisector(xAccessor).left;
        let index = bisector(dataNest[nestIndex].value, date);
        if (date.getDate() <= 15 && date.getDate() >= 1) {
          index = index-1;
        }
        let stock = ""
        nestIndex = -1;
        while ((typeof stock === "undefined" || stock === "") && nestIndex < dataNest.length) {
          ++nestIndex;
          index = bisector(dataNest[nestIndex].value, date);
          if (date.getDate() <= 15) {
            index = index-1;
          }
          stock = dataNest[nestIndex].value[index];
          if (stock) {
            const rangeDate = new Date(stock.Period);
            const diffTime = Math.abs(rangeDate - date);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if (diffDays >= 35) {
              stock = "";     
            }         
          }
        }
        dataNest.sort((a, b) => yAccessor(b.value[index]) - yAccessor(a.value[index]));
        verticalLine
          .style('opacity', 1)
          .style('stroke', '#5E5F5F')
          .style('stroke-width', '3px')
          .style('pointer-events', 'none')
          .attr('x1', x(xAccessor(stock)))
          .attr('y1', 0)
          .attr('x2', x(xAccessor(stock)))
          .attr('y2', dimensions.ctrHeight)
          .raise();
        if (!mobile) {
          tooltip
            .data(dataNest)
            .style('display', 'block')
            .style('pointer-events', 'none')
            .style('position', 'absolute')
            .style('top', y(yAccessor(dataNest[nestIndex].value[index])) + 100 + 'px')
            .style('left', x(xAccessor(dataNest[nestIndex].value[index])) + 'px')
            .style('width', 'auto')
            .style('bottom', 'initial')
            .style('transform', 'initial')
            .style('z-index', 10001)
            .attr('class', 'tooltip')
            .style('color', 'white') // (d) => color(d.key))
            .html(`${xaxisFormatTooltip(xAccessor(dataNest[nestIndex].value[index]))} <br> ${Metric}`)
            .append('div')
            .attr('class', 'subtooltip_a')
            .selectAll()
            .data(dataNest)
            .enter()
            .append('div')
            .attr('class', 'tooltipMain')
            .html((d) => {
              if (typeof d.value[index] !== 'undefined') {
                return (
                  `
                  <div class="subTooltip_b">
                    <text numberOfLines={1} style="color: ${color(d.key)};" class="subTooltipText">${d.key}
                    </text>
                  </div>
                  <div style="display: flex; flex-direction: row; gap: 4px" >
                  <div class="subTooltip_d">
                    <div class="circle" style="border-color: ${color(d.key)}">
                    </div>
                  </div>
                  <div class="subTooltip_c">
                    <text style="color: ${color(d.key)};" class="subTooltipTextNo">
                      ${tooltipFormatter(yAccessor(d.value[index]))}
                    </text>
                  </div>
                  </div>
                  `
                );
              }
            })
        } else {
          tooltip
          .data(dataNest)
          .style('display', 'block')
          .style('position', 'fixed')
          .style('pointer-events', 'none')
          .style('width', '80vw')
          .style('top', 'initial')
          .style('max-width', '460px')
          .style('bottom', `${dimensions.ctrHeight*1.35}px`)
          .style('left', '50%')
          .style('transform', 'translateX(-50%)')
          .style('z-index', 10001)
          .attr('class', 'tooltip')
          .style('color', 'white') // (d) => color(d.key))
          .html((d) => `${xaxisFormatTooltip(xAccessor(dataNest[nestIndex].value[index]))} <br> ${Metric}`)
          .append('div')
          .attr('class', 'subtooltip_a')
          .selectAll()
          .data(dataNest)
          .enter()
          .append('div')
          .attr('class', 'tooltipMain')
          .html((d) => {
            if (typeof d.value[index] !== 'undefined') {
              return (
                `
                <div class="subTooltip_b">
                  <text numberOfLines={1} style="color: ${color(d.key)};" class="subTooltipText">${d.key}
                  </text>
                </div>
                <div style="display: flex; flex-direction: row; gap: 4px" >
                <div class="subTooltip_d">
                  <div class="circle" style="border-color: ${color(d.key)}">
                  </div>
                </div>
                <div class="subTooltip_c">
                  <text style="color: ${color(d.key)};" class="subTooltipTextNo">
                    ${tooltipFormatter(yAccessor(d.value[index]))}
                  </text>
                </div>
                </div>
                `
              );
            }
          })
        }
        tooltip
          .append('div')
          .attr('class', 'subtooltip_e')
          .html((d) => {
            const nameEvent = nameChanges.find((x) => x.date === dataNest[nestIndex].value[index].Period);
            let nameEventHtml = ``;
            if (nameEvent) {
              nameEventHtml = 
              `
              <div class="nameChangeFrame"> 
                <img src="/static/icons/nameChangeIcon.svg" />
                Name change event
              </div>
              `
            }
            return nameEventHtml;
          })
          .append('div')
          .attr('class', 'tooltipMain_event')
          .selectAll()
          .data(dataNest)
          .enter()
          .append('div')
          .attr('class', 'tooltipMain')
          .html((d) => {
            if (typeof d.value[index] !== 'undefined') {
              const nameEvent = nameChanges.find((x) => x.new === d.key && x.date === d.value[index].Period);
              let nameEventHtml = ``;
              if (nameEvent) {
                nameEventHtml = 
                `
                <div class="subTooltip_b">
                  <text numberOfLines={3} style="color: ${color(d.key)};" class="nameChangeText">
                    <strong> ${nameEvent.old} </strong> changed name to <strong> ${nameEvent.new} </strong>
                  </text>
                </div>
                </div>
                `;
              }
              return nameEventHtml;
            }
          });
        tooltip
          .append('div')
          .attr('class', 'subtooltip_e')
          .html((d) => {
            const mergeEvent = mergeChanges.find((x) => x.date === dataNest[nestIndex].value[index].Period);
            let mergeEventHtml = ``;
            if (mergeEvent) {
              mergeEventHtml = 
              `
              <div class="nameChangeFrame"> 
                <img src="/static/icons/mergeEvent.svg" />
                Merge event
              </div>
              `
            }
            return mergeEventHtml;
          })
          .append('div')
          .attr('class', 'tooltipMain_event')
          .selectAll()
          .data(dataNest)
          .enter()
          .append('div')
          .attr('class', 'tooltipMain')
          .html((d) => {
            if (typeof d.value[index] !== 'undefined') {
              const mergeEvent = mergeChanges.find((x) => x.old === d.key && x.date === d.value[index].Period);
              let mergeEventHtml = ``;
              if (mergeEvent) {
                mergeEventHtml = 
                `
                  <text numberOfLines={3} style="color: ${color(d.key)};" class="nameChangeText">
                    <strong> ${mergeEvent.old} </strong> merged with <strong> <span style="color: ${color(d.key)}">${mergeEvent.new}</span> </strong>
                  </text>
                `;
              }
              return mergeEventHtml;
            }
          });
      }

      function update(ADIs, Metric, ADIType) {
        ctr.selectAll('circle').remove();
        if (Metric !== '<Metric>') {
          yAccessor = (d) => {
            if (typeof d === 'undefined') {
              return 0.1;
            }
            return d[Metric] * 1000000;
          };
          yAccessorUp = (d) => d[Metric + ' upper'] * 1000000;
          yAccessorDown = (d) => d[Metric + ' lower'] * 1000000;
          dataNest = dataNest.sort((a, b) => a.value.length - b.value.length);
          const ymin = d3.min([
            d3.min(datasetFilter, (d) => yAccessor(d))
          ]);
          y.domain([0.8*ymin, 1.15*d3.max(datasetFilter, (d) => yAccessor(d))]);
          x.domain(d3.extent(datasetFilter, xAccessor));
          const lineGenerator = d3
            .line()
            .x((d) => x(xAccessor(d)))
            .y((d) => y(yAccessor(d)));
          const areaGenerator = d3
            .area()
            .x((d) => x(xAccessor(d)))
            .y0((d) => {
              let y_value = y(yAccessorUp(d));
              if (y_value > dimensions.ctrHeight-dimensions.margins) {
                y_value = dimensions.ctrHeight;
              } else if (y_value < 0) {
                y_value = 0;
              }
              return y_value;
            })
            .y1((d) => {
              let y_value = y(yAccessorDown(d));
              if (y_value > dimensions.ctrHeight-dimensions.margins) {
                y_value = dimensions.ctrHeight;
              } else if (y_value < 0) {
                y_value = 0;
              }
              return y_value;
            });
          // const axisTransition = d3.transition().duration(200);
          // const lineTransition = axisTransition.transition().duration(200);
          
          // if Missing ADIS
          if (missingDataADIs.length > 0) {
            if (dimensions.ctrWidth < mobileWidth) {

            }
            ctrSorry.append('foreignObject')
              .attr('id', 'WeAreSorry')
              .attr("width", width)
              .attr("height", height)
              .attr('fill', 'red')
              //.style('pointer-events', 'none')
              .append('xhtml:div')
              .style('display', 'block')
              .style('position', 'absolute')
              .style('z-index', 100002)
              .attr('class', 'tooltip')
              .style('color', 'white')
              .style('max-width', `240px`)
              .style('left', '50%')
              .style('transform', 'translateX(-50%)')
              .html(`
                <div style="display: flex; flex-direction: row; justify-content: space-between; padding-right: 4px;">
                  <div>
                  We're sorry. 
                  </div>
                  <button onclick="document.getElementById('WeAreSorry').remove()"class="mobileButton">
                    <img  style="width: 12px; height: 12px" src="/static/icons/X.svg"/>
                  </button>
                </div>
                `
              )
              .append('div')
              .attr('class', 'missingData_subheading')
              .html(`It seems like there is no relevant data for the following institutions:`)
              .append('div')
              .attr('class', 'missingData_e')
              .selectAll()
              .data(missingDataADIs)
              .enter()
              .append('div')
              .attr('class', 'missingData_a')
              .html((d) => {
                if (typeof d !== 'undefined') {
                  return (
                    `
                    <div class="missingData_d">
                      <div class="circle" style="border-color: #5E5F5F">
                      </div>
                    </div>
                    <div class="missingData_b">
                      <text numberOfLines={1} style="color: #5E5F5F;" class="missingDataText">${d}
                      </text>
                    </div>
                    `
                  );
                }
              });
          }
          if (datasetFilter.length < 1) {
            return;
          }
          const maxDate = datasetFilter.reduce((prev, current) => (prev.Period > current.Period) ? prev : current).Period;
          const datasetLegend = datasetFilter.filter((d) => d.Period >= maxDate);
          const labels = datasetLegend.map(d => {
            return {
              fx: 0,
              targetY: y(yAccessor(d))
            };
          });
          // Set up the force simulation
          const force = d3.forceSimulation()
          .nodes(labels)
          .force('collide', d3.forceCollide(10).iterations(1))
          .force('boundary', forceBoundary(0, 0, dimensions.width, 0.99*dimensions.ctrHeight))
          .force('y', d3.forceY(d => d.targetY).strength(2))    
          .stop();
          // Execute the simulation
          for (let i = 0; i < 100; i++) force.tick();

          // Assign values to the appropriate marker
          let labelFontSize = "12px";
          if (dimensions.ctrWidth < mobileWidth) {
            labelFontSize = "8px";
          }
          labels.sort((a, b) => a.y - b.y);
          datasetLegend.sort((a, b) => b[Metric] - a[Metric]);
          datasetLegend.forEach((d, i) => {
            d.y = labels[i].y
            legend = ctrL
              .append("svg:foreignObject")
              .datum(d)
              .attr("width", 0.18*width)
              .attr("height", 40) // height needs to be fixed, overflow is clipped.
              .attr('x', dimensions.ctrWidth + 10)// )x(xAccessor(d.value[index])) + 10 + 'px') // space legend
              .attr('y', (d) => d.y-10)// )y(yAccessor(d.value[index])) + 'px')
              .style("text-align", "left") // text-anchor is not working but css style is possible
              .style('font-size', labelFontSize)
              .style('text-anchor', 'left')
              .style('max-width', `${0.1*width}px`)
              .style('overflow', 'hidden')
              .style('text-overflow', 'ellipsis')
              .style('color', d => color(d.ADI))
              //.transition(lineTransition)
              .append('xhtml:div')
              .html((d) => {
                if (typeof d !== 'undefined') {
                  return (
                    `
                    <div>
                      <text numberOfLines={2} style="color: ${color(d.ADI)}; font-size: ${labelFontSize};" class="label">${d.ADI}
                      </text>
                    </div>
                    `
                  );
                }
              });
          });
          // First prediction date
          const hasPredictions = datasetFilter.filter((da) => da.prediction === true);
          if (hasPredictions.length > 0) {
            const firstPredictionDate = hasPredictions.reduce((prev, current) => (prev.Period < current.Period) ? prev : current).Period;
            // Smart Prediction Line
            smartPredictionLine
              .style('opacity', 1)
              .style('stroke', '#D36B28')
              .style('stroke-width', '1.5px')
              .attr('x1', x(tParser(firstPredictionDate)))
              .attr('x2', x(tParser(firstPredictionDate)))
              .attr('y1', 0)
              .transition(lineTransition)
              .attr('y2', dimensions.ctrHeight)
            smartPredictionArea
              .attr('x', x(tParser(firstPredictionDate)))
              .attr('y', 0)
              .style('opacity', 0.03)
              .style('fill', '#D36B28')
              .transition(lineTransition)
              .attr('height', dimensions.ctrHeight)
              .attr('width', dimensions.ctrWidth - x(tParser(firstPredictionDate)))
            ctr
              .append("text")
              .attr('text-anchor', 'middle')
              .style('font-size', '11px')
              .style('fill', '#D36B28')
              .attr('x', x(tParser(firstPredictionDate)))
              .attr('y', -10)
              .transition(lineTransition)
              .text("Smart Prediction")
          }
          dataNest.forEach((d, i) => {
            d.value.sort(function(x, y){
              return d3.ascending(x.Period, y.Period);
            })
            // Since list is ordered, index 0 corresponds to min date
            // Starting date = 2019-03-31
            /* Metrics which have different starting points, have indexes which correspond to different dates
              i.e, if one starts at Jan 2020, Index 0 will be at Jan 2020 for this metric;
              if the other starts at Jan 2021, Index 0 will be at Jan 2021 for this metric.
              We need to make sure that all graphs have the same starting point (APR 2019?), and as such, all values are the same length.
            */
            const nMonthsLeft = monthDiff(new Date('2019-03-31'), new Date(d.value[0].Period));
            const emptyArray = new Array(nMonthsLeft);
            d.value = emptyArray.concat(d.value);
            // Show confidence interval
            areapath = ctr
              .append('path')
              .datum(d)
              .transition(lineTransition)
              .attr('fill', (d) => color(d.key))
              .attr('opacity', 0.2)
              .attr('stroke', 'none')
              .attr('d', areaGenerator(d.value.filter((da) => da.prediction === true)));
            linepath = ctr
              .append('path')
              .datum(d)
              .transition(lineTransition)
              .attr('d', lineGenerator(d.value.filter((da) => da.prediction === true)))
              .attr('fill', 'none')
              .attr('class', 'line')
              .style('stroke-dasharray', '3, 3')
              .attr('stroke', (d) => color(d.key))
              .attr('stroke-width', 2);
            linepath = ctr
              .append('path')
              .datum(d)
              .transition(lineTransition)
              .attr('d', lineGenerator(d.value.filter((da) => da.prediction === false)))
              .attr('fill', 'none')
              .attr('class', 'line')
              .attr('stroke', (d) => color(d.key))
              .attr('stroke-width', 2);
            });    
          //Name change events
          if (nameChanges.length > 0) {
            const datasetEvents = datasetFilter.filter((d) => d.eventType === 'name');
            sigEvents = ctrd
              .selectAll('circle')
              .data(datasetEvents)
              .join('circle')
              .attr('cx', (d) => x(xAccessor(d)))
              .attr('cy', (d) => y(yAccessor(d)))
              .attr('fill', (d) => color(d.ADI))
              .style('stroke-width', '4px')
              .style('stroke', (d) => color(d.ADI))
              .style('pointer-events', 'none')
              .attr('r', 7)
              .attr('opacity', 0)
              .transition(lineTransition)
              .attr('opacity', 1)
            datasetEvents.forEach((d) => {
              sigEventsLine = ctrd
                .append('line')
                .attr('x1', x(xAccessor(d))-5)
                .attr('x2', x(xAccessor(d))+5)
                .attr('y1', y(yAccessor(d)))
                .attr('y2', y(yAccessor(d)))
                .attr('stroke', 'white')
                .attr('stroke-width', 3)
                .style('pointer-events', 'none')
              sigEventsLine = ctrd
                .append('line')
                .attr('x1', x(xAccessor(d)))
                .attr('x2', x(xAccessor(d)))
                .attr('y1', y(yAccessor(d))-5)
                .attr('y2', y(yAccessor(d))+5)
                .attr('stroke', 'white')
                .attr('stroke-width', 3)
                .style('pointer-events', 'none')
              });
            }
          //merge events
          if (mergeChanges.length > 0) {
            const datasetEvents = datasetFilter.filter((d) => d.eventType === 'merge');
            sigEvents = ctre
              .selectAll('circle')
              .data(datasetEvents)
              .join('circle')
              .attr('cx', (d) => x(xAccessor(d)))
              .attr('cy', (d) => y(yAccessor(d)))
              .attr('fill', (d) => color(d.ADI))
              .style('stroke-width', '4px')
              .style('stroke', (d) => color(d.ADI))
              .style('pointer-events', 'none')
              .attr('r', 7)
              .attr('opacity', 0)
              .transition(lineTransition)
              .attr('opacity', 1)
            datasetEvents.forEach((d) => {
              sigEventsLine = ctre
                .append('line')
                .attr('x1', x(xAccessor(d))-5)
                .attr('x2', x(xAccessor(d))+5)
                .attr('y1', y(yAccessor(d)))
                .attr('y2', y(yAccessor(d)))
                .attr('stroke', 'white')
                .attr('stroke-width', 3)
                .style('pointer-events', 'none')
              sigEventsLine = ctre
                .append('line')
                .attr('x1', x(xAccessor(d)))
                .attr('x2', x(xAccessor(d)))
                .attr('y1', y(yAccessor(d))-5)
                .attr('y2', y(yAccessor(d))+5)
                .attr('stroke', 'white')
                .attr('stroke-width', 3)
                .style('pointer-events', 'none')
            });
          }
          let circleRadius = 3;
          if (dimensions.ctrWidth < mobileWidth) {
            circleRadius = 2;
          }
          ctr
            .selectAll('circle')
            .data(datasetFilter)
            .join('circle')
            .attr('cx', (d) => x(xAccessor(d)))
            .attr('cy', (d) => y(yAccessor(d)))
            .attr('fill', 'white')
            .style('stroke-width', '2px')
            .style('stroke', (d) => color(d.ADI))
            .attr('r', circleRadius)
            .attr('opacity', 0)
            .transition(lineTransition)
            .attr('opacity', 1);
          if (dataNest.length > 0) {
            dataNest.forEach((d, i) => {
              ctr
                .append('rect')
                .attr('width', dimensions.ctrWidth)
                .attr('height', dimensions.ctrHeight)
                .style('opacity', 0)
                .on('touchmouse mousemove', (event) => drawTooltip(d, event, dataNest))
                .on('mouseleave', (event) => {
                  verticalLine.style('opacity', 0);
                  tooltip.style('display', 'none');
                });
            });
          }
          let tickSizeInner = 15.33;
          let tickFontSize = "12px";
          if (dimensions.ctrWidth < mobileWidth) {
            tickSizeInner = 5;
            tickFontSize = "8px";
          }
          const yAxis = d3.axisLeft(y)
            .tickFormat((d) => yaxisformat(d).replace(/G/, 'B'))
            .tickSizeOuter(0)
            .tickSizeInner(tickSizeInner*0.66);
          const xAxis = d3
            .axisBottom(x)
            .tickFormat((d) => xaxisformat(d))
            .tickSizeOuter(0)
            .tickSizeInner(tickSizeInner);
          if (dimensions.ctrWidth < mobileWidth) {
            xAxisGroup
              .call(xAxis)
              .selectAll(".tick text")
              .attr('font-size', tickFontSize)
              .call(wrap, 20);
            yAxisGroup.call(yAxis)
              .selectAll(".tick text")
              .attr('font-size', tickFontSize);
          } else {
            xAxisGroup.call(xAxis)
            yAxisGroup.call(yAxis);
          }
          y.ticks().forEach((d) => {
            yAxisGroup
              .append('line')
              .style('opacity', 1)
              .style('stroke-width', 1)
              .style('stroke', '#D36B2833')
              .style('fill', 'none')
              .style('stroke-dasharray', '3, 3')
              .attr('x1', 0)
              .attr('y1', y(d))
              .attr('x2', dimensions.ctrWidth)
              .attr('y2', y(d))
              .lower();
          });
        }
      }
      update(ADIs, Metric, ADIType);
    }
    d3.select(window).on('resize', function() {
      const reDrawWidth = parseInt(d3.select("#App-header").style('width'))
      const reDrawHeight = parseInt(svg.style("height"))
      width = 0.95*reDrawWidth;
      height = reDrawHeight;
      redraw(0.95*reDrawWidth, reDrawHeight, d3.transition().duration(0), d3.transition().duration(0));
    });
  });


  return (
    <svg
      ref={ref}
      style={{
        overflow: 'visible',
        preserveAspectRatio: 'none',
        marginRight: '0px',
        marginLeft: '0px',
      }}
    >
      <g className="plot-area" />
      <g className="x-axis" />
      <g className="y-axis" />
    </svg>
  );
}
export default LineChart;
