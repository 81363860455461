// scroll bar
import 'simplebar/src/simplebar.css';
import Amplify from 'aws-amplify';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import awsExports from './aws-exports';
import { AmplifyProvider, Authenticator } from "@aws-amplify/ui-react";
//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
// Importing the Bootstrap CSS
// import './bootstrap.min.css';
import './react-buttons.css';
import './react-dropdown.css';

Amplify.configure(awsExports);
// ----------------------------------------------------------------------

ReactDOM.render(
  <AmplifyProvider>
    <Authenticator.Provider>
      <HelmetProvider>
        <style>{'body { background-color: #5E5F5F; }'}</style>
        <BrowserRouter>
          <App />
        </BrowserRouter>
    </HelmetProvider>
    </Authenticator.Provider>
  </AmplifyProvider>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
